import {Button, Modal, Spin} from "antd";
import React, {useEffect, useState} from "react";
import {useApi} from "../ApiProvider";
import {ProjectDetailsDto} from "../../generated/api";
import {formatCost} from "./Projects";
import {formatPubJournal, formatPubSuffix} from "../PeoplePublications";

interface ProjectPopupProps {
    id?: {fullProjectNum: string, year: number, subprojectId: string}
    onClose: ()=>void
}

export function ProjectPopup(props: Readonly<ProjectPopupProps>) {
    const api = useApi();
    const [data, setData] = useState<ProjectDetailsDto>();
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (props.id) {
            setLoading(true)
            api.projectDetails(props.id.fullProjectNum, props.id.year, props.id.subprojectId).then(response=>{
                setLoading(false)
                setData(response.data)
            })
        }
    }, [api, props.id]);

    return <Modal open={!!props.id}
                  centered={true}
                  onCancel={()=>props.onClose()}
                  title={<div style={{display: 'flex', alignItems: 'center', gap: 15}}>
                      {props.id?.fullProjectNum} ({props.id?.year})
                  </div>}
                  width={900}
                  footer={<Button type="primary" onClick={()=>props.onClose()}>
                      Close
                  </Button>}
    >
        <Spin spinning={loading}>
            {data && <>
                <table>
                    <tbody>
                    <tr>
                        <td>Title:</td>
                        <td>{data.projectTitle}</td>
                    </tr>
                    <tr>
                        <td>Year:</td>
                        <td>{data.fy}</td>
                    </tr>
                    <tr>
                        <td>Core #:</td>
                        <td>{data.coreProjectNum}</td>
                    </tr>
                    <tr>
                        <td>Full #:</td>
                        <td>{data.fullProjectNum}</td>
                    </tr>
                    <tr>
                        <td>Sub Project #:</td>
                        <td>{data.subprojectId}</td>
                    </tr>
                    <tr>
                        <td>Activity:</td>
                        <td>{data.activity}</td>
                    </tr>
                    <tr>
                        <td>Budget Start:</td>
                        <td>{data.budgetStart}</td>
                    </tr>
                    <tr>
                        <td>Budget End:</td>
                        <td>{data.budgetEnd}</td>
                    </tr>
                    <tr>
                        <td>PI Names:</td>
                        <td>{data.piNames}</td>
                    </tr>
                    <tr>
                        <td>Organization Name:</td>
                        <td>{data.orgName}</td>
                    </tr>
                    <tr>
                        <td>Project Start:</td>
                        <td>{data.projectStart}</td>
                    </tr>
                    <tr>
                        <td>Project End:</td>
                        <td>{data.projectEnd}</td>
                    </tr>
                    <tr>
                        <td>Total Cost:</td>
                        <td>{formatCost(data.totalCost ? data.totalCost : data.totalCostSubProject)}</td>
                    </tr>
                    <tr>
                        <td>Funding Mechanism:</td>
                        <td>{data.fundingMechanism}</td>
                    </tr>
                    <tr>
                        <td>IC Name:</td>
                        <td>{data.icName}</td>
                    </tr>
                    <tr>
                        <td>Opportunity Number:</td>
                        <td>{data.opportunityNumber}</td>
                    </tr>
                    </tbody>
                </table>
                {(data?.publications?.length??0)>0 && <>
                    <h4>Publications for Core Project Number: {data.coreProjectNum}</h4>
                    <ol style={{paddingLeft: 29}}>
                        {data.publications?.map(x => <li key={x.pmid} style={{paddingLeft: 6, paddingBottom: 6}}>
                            <div>{x.title} {formatPubSuffix(x)}</div>
                            <div style={{color: '#aaa'}}>{x.authorsList.map((z,i)=>{
                                return <>
                                    {i>0 && <>, </>}
                                    <span title={z.affiliations?.join("\n\n")}>
                                        {z.personId!==null?
                                            <span className='highlight-author' data-idx={z.personId}>{z.lastName+" "+z.initials}</span>:
                                            <>{z.lastName+" "+z.initials}</>}
                                    </span>
                                </>
                            })}</div>
                            {formatPubJournal(x)}
                        </li>)}
                    </ol>
                </>}
            </>}
        </Spin>
    </Modal>
}