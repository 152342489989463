/* eslint-disable jsx-a11y/anchor-is-valid */
import {Navigation, SettingsContext} from "./Navigation";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {
    DiffDto,
    DivisionNameDto,
    DivisionYearsDto,
    PersonDtoWithId,
    PersonIndexStatusProjection,
    PersonNameDtoWithId,
    PersonStatsDto,
    SnapshotDto
} from "../generated/api";
import {useApi} from "./ApiProvider"
import {PersonEdit} from "./PersonEdit";
import {Button, Input, Modal, Popconfirm, Select, Table} from "antd";
import {DeleteOutlined, ExclamationCircleOutlined, FilterOutlined, LoadingOutlined} from "@ant-design/icons";
import Column from "antd/es/table/Column";
import {PublicationsPopup} from "./PublicationsPopup";
import {useLocation, useNavigate} from "react-router-dom";
import {formatName, processDownload} from "../utils/utils";
import {UploadDataPopup} from "./UploadDataPopup";
import {PubDiff} from "./PubDiff";
import {useRolesProvider} from "../utils/keycloakUtils";
import {CatalystImportPopup} from "./CatalystImportPopup";
import {stopPropagationUtil} from "../utils/clicks";
import {SettingsHolderContext} from "./SettingsHolder";
import {BaseOptionType} from "rc-select/lib/Select";

export const Ranks : {[key: string]: string} = {
    'none': 'None',
    'instructor': 'Instructor',
    'assistant': 'Assistant Professor',
    'associate': 'Associate Professor',
    'professor': 'Professor'
}

export const ShortRanks : {[key: string]: string} = {
    'none': 'None',
    'instructor': 'Instr',
    'assistant': 'Asst',
    'associate': 'Assoc',
    'professor': 'Prof'
}

export const RankOrder = ['none', 'instructor', 'assistant', 'associate', 'professor']

interface DisplayCountersProps {
    showHarvard: ()=>void
    showPubmed: ()=>void
    showV1: ()=>void
    showSnapshot: ()=>void
    isAdmin: boolean
    record: PersonIndexStatusProjection
    setSelectedPerson: ()=>void
}

function DisplayCounters(props: DisplayCountersProps) {
    // noinspection PointlessBooleanExpressionJS
    return props.isAdmin?
        <>
            <a href={"#"} onClick={props.setSelectedPerson}>{props.record.count}/{props.record.deletedCount}</a>
            {props.record.harvardCatalystQueryCount!==null && <a onClick={props.showHarvard}><span className={"hc-tag"}>HC: {props.record.harvardCatalystQueryCount}</span></a>}
            <a onClick={props.showPubmed} href={"#"}><span className={"pubmed-tag"}>PubMed: {props.record.pubmedLastNameQueryCount}</span></a>
            {props.record.algoV1Count!=undefined && props.record.algoV1Count!=null && <a onClick={props.showV1} href={"#"}><span className={"v1-tag"}>V1: {props.record.algoV1Count}</span></a>}
            {
                (!!props.record.selectedSnapshotCount || !!props.record.selectedSnapshotDeleted) &&
                <a onClick={props.showSnapshot} href={"#"}>
                    <span
                        className={"snapshot-tag"}>Snapshot: {props.record.selectedSnapshotCount}/{props.record.selectedSnapshotDeleted}</span>
                </a>
            }
            {props.record.differentMiddleNamesFound && <ExclamationCircleOutlined style={{color: 'red'}}/>}
        </> :
        <a onClick={props.setSelectedPerson}>{props.record.count}</a>
}

export function People() {
    const location = useLocation();

    const [personEdit, setPersonEdit] = useState<{open: boolean, id?: number}>({open: false})
    const [people, setPeople] = useState<PersonStatsDto[]>();
    const [divisionNames, setDivisionNames] = useState<DivisionNameDto[]>();
    const [filter, setFilter] = useState("");
    const [divisionFilter, setDivisionFilter] = useState<number>(location.state?.id);
    const [statuses, setStatuses] = useState<{[id: number]: PersonIndexStatusProjection}>({})
    const [uploadOpen, setUploadOpen] = useState<boolean>(location.state?.openLoad??false)
    const [pubDiffOpen, setPubDiffOpen] = useState<{open: boolean, personId?: number, title?: string, getData?: ()=>Promise<DiffDto>}>({open: false})
    const [isUpdating, setUpdating] = useState(false)
    const [currentSnapshotId, setCurrentSnapshotId] = useState<number>()
    const [snapshots, setSnapshots] = useState<SnapshotDto[]>([])
    const [snapshotNameOpen, setSnapshotNameOpen] = useState(false);
    const [snapshotName, setSnapshotName] = useState<string>();
    const [importOpen, setImportOpen] = useState(false)

    const [selectedPerson, setSelectedPerson] = useState<PersonNameDtoWithId>()

    const rolesProvider = useRolesProvider()

    const api = useApi()

    const settingsHolder = useContext(SettingsHolderContext)
    const navigate = useNavigate()

    if (settingsHolder?.inst==="NONEXISTENT") {
        navigate("/institutions")
    }


    function getSnapshots() {
        return api.getSnapshots().then(response=> {
            setSnapshots(response.data)
        });
    }

    useEffect(()=>{
        if (rolesProvider.isAdmin()) {
            getSnapshots().then()
        }
    }, [])
    
    useEffect(() => {
        if (isUpdating) {
            let cancelled = false;
            let lastUpdate = new Date().valueOf()
            const func = () => {
                api.personIndexStatus(currentSnapshotId, {_noMessage: true} as any).then(response => {
                    if (response.data.filter(x=>x.refreshStatus).length>0) {
                        lastUpdate = new Date().valueOf()
                    }
                    const newStatuses = Object.fromEntries(response.data.map(x => [x.id, x]))
                    setStatuses(oldStatuses => {
                        if (JSON.stringify(oldStatuses) !== JSON.stringify(newStatuses)) {
                            return newStatuses
                        }
                        return oldStatuses
                    })
                }).finally(() => {
                    if (!cancelled) {
                        if (new Date().valueOf()-lastUpdate<300*1000) {
                            setTimeout(func, 2000);
                        } else {
                            setUpdating(false)
                        }
                    }
                })
            }
            func()
            return () => {
                cancelled = true;
            }
        }
    }, [api, isUpdating]);

    function refreshData() {
        api.listPersons(divisionFilter, currentSnapshotId).then(response => {
            setPeople(response.data)
            api.personIndexStatus(currentSnapshotId).then(response=>{
                setUpdating(response.data.filter(x=>x.refreshStatus).length>0)
            })
        })
        api.listDivisionNames().then(response => {
            response.data.sort((a, b) => a.title.localeCompare(b.title, undefined, {sensitivity: 'base'}))
            setDivisionNames(response.data)
        })
    }

    useEffect(()=>{
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api, divisionFilter, currentSnapshotId])

    function sortAndFilter(datasource: PersonDtoWithId[], filter: string) {
        let ret = datasource.filter(x=>!filter || formatName(x).toLowerCase().includes(filter.toLowerCase()))
        ret.sort((a,b)=>(a.lastName??"").localeCompare(b.lastName??"", undefined, {sensitivity: 'base'}))
        return ret
    }

    function refreshAll() {
        api.reindexAll().then(()=>{
            setUpdating(true);
        });
    }

    function deleteAll() {
        api.deleteAllPersons().then(()=>{
            refreshData()
        });
    }

    function renderRange(v: DivisionYearsDto) {
        return <span style={{color: "#bbb"}}>(
            {v.startMon}
            {!!v.startMon && "/"}
            {v.startDay}
            {!!v.startDay && "/"}
            {v.startYear}
            &ndash;
            {v.endMon}
            {!!v.endMon && "/"}
            {v.endDay}
            {!!v.endDay && "/"}
            {v.endYear}
            )
        </span>
    }

    function renderDivisions(record: PersonDtoWithId) {
        if (!record.divisions) return "";
        return <>{
            record.divisions.map((v, i)=><React.Fragment key={v.divisionId}>
                {i>0 && <>, </>}
                {v.title} {(v.startYear || v.endYear) && renderRange(v)}
            </React.Fragment>)
        }</>
    }

    function downloadCSV() {
        api.loadCSV({responseType: "arraybuffer"}).then(processDownload('people_list.csv'))
    }

    function showHarvard(id: number) {
        setPubDiffOpen({
            open: true,
            title: "Diff with Harvard Catalyst",
            personId: id,
            getData: async ()=>{
                const response = await api.getHarvardDiff(id);
                return response.data
            }
        });
    }

    function showV1Diff(id: number) {
        setPubDiffOpen({
            open: true,
            title: "Diff with Algorithm v1",
            personId: id,
            getData: async ()=>{
                const response = await api.getAlgoDiff("v1", id);
                return response.data
            }
        });
    }

    function showPubmed(id: number) {
        setPubDiffOpen({
            open: true,
            title: "Diff with Pubmed",
            personId: id,
            getData: async ()=>{
                const response = await api.getPubmedDiff(id);
                return response.data
            }
        });
    }

    function showSnapshot(id: number) {
        if (currentSnapshotId) {
            setPubDiffOpen({
                open: true,
                title: "Diff with Snapshot #" + currentSnapshotId,
                personId: id,
                getData: async () => {
                    const response = await api.getSnapshotDiff(currentSnapshotId, id);
                    return response.data
                }
            });
        }
    }

    function closeDiffPopup() {
        setPubDiffOpen({open: false});
    }

    function createSnapshot() {
        if (snapshotName) {
            api.createSnapshot({name: snapshotName}).then(response => {
                getSnapshots().then(() => {
                    setCurrentSnapshotId(response.data.id)
                    setSnapshotNameOpen(false);
                })
            })
        }
    }

    function removeSnapshot(id: number) {
        api.removeSnapshot(id).then(()=>{
            getSnapshots().then(()=>{
                if (id===currentSnapshotId) {
                    setCurrentSnapshotId(undefined);
                }
            })
        })
    }

    function formatDate(created: string) {
        return new Date(created).toLocaleDateString();
    }

    const optionRender = useCallback((x: BaseOptionType)=><div style={{display: 'flex'}}>
        <span>{x.label}</span>
        <div style={{flex: 1}} />
        <Button icon={<DeleteOutlined/>}
                type={"text"}
                size={"small"}
                danger={true}
                onClick={e=>{
                    e.preventDefault()
                    stopPropagationUtil(e)
                    removeSnapshot(Number(x.value))
                }}/>
    </div>, [])

    function showRefreshStatus(id: number) {
        if (statuses[id].refreshStatus) {
            if (!statuses[id].lastRefreshed || rolesProvider.isAdmin()) {
                return <LoadingOutlined/>
            }
        }
    }

    function renderRank(v: string, r: PersonStatsDto) {
        return <>{Ranks[v]}
            {!!r.academicRankYear && <span style={{color: "#bbb"}}> (
            {r.academicRankMon}
            {!!r.academicRankMon && "/"}
            {r.academicRankDay}
            {!!r.academicRankDay && "/"}
            {r.academicRankYear})</span>}
        </>
    }

    return <Navigation breadcrumbs={[{title: 'Manage People'}]}>
        <PubDiff open={pubDiffOpen.open}
                 title={pubDiffOpen.title}
                 onCancel={closeDiffPopup}
                 footer={<Button type="primary" onClick={closeDiffPopup}>
                     Close
                 </Button>}
                 getData={pubDiffOpen.getData}
                 personId={pubDiffOpen.personId}
        />
        <Modal open={snapshotNameOpen} afterClose={()=>setSnapshotName('')} onOk={createSnapshot} onCancel={()=>setSnapshotNameOpen(false)}>
            Snapshot Name: <Input value={snapshotName} onChange={x=>setSnapshotName(x.target.value)}/>
        </Modal>
        <CatalystImportPopup open={importOpen} onClose={imported=>{
            setImportOpen(false)
            if (imported) {
                refreshData()
                setUpdating(true)
            }
        }} />
        <PersonEdit open={personEdit.open}
                    divisions={divisionNames}
                    personId={personEdit.id}
                    onClose={(updated)=>{
                          if (updated!==undefined) {
                              setPeople((old) => {
                                      const filtered = (old??[]).filter(x => !personEdit.id || x.id !== personEdit.id)
                                      return updated ?
                                          [...filtered,
                                              {
                                                  ...updated,
                                                  count: 0,
                                                  differentMiddleNamesFound: false,
                                                  harvardCatalystQueryCount: 0,
                                                  pubmedLastNameQueryCount: 0
                                              }
                                          ] : filtered
                                  }
                              )
                              setUpdating(true)
                          }
                          setPersonEdit({open: false})
                    }}
        />
        <SettingsContext.Consumer>
            {settings=><>{settings && <PublicationsPopup globalStartYear={settings.startYear}
                                                         person={selectedPerson}
                                                         onClose={shouldRefresh=>{
                                                             setSelectedPerson(undefined)
                                                             if (shouldRefresh) {
                                                                 refreshData()
                                                             }
                                                         }}/>}</>}
        </SettingsContext.Consumer>
        <UploadDataPopup isOpen={uploadOpen}
                         onClose={()=>setUploadOpen(false)}
                         onLoaded={()=>{
                             setUploadOpen(false)
                             refreshData();
                         }}
        />
        <div className={"page-with-sub-header"}>
            <div className={"sub-header"}>
                <Input value={filter} onChange={e=>setFilter(e.target.value)} size="large" placeholder="filter by name" prefix={<FilterOutlined />} style={{width: 200}} />
                <Select
                    placeholder={"Filter by division"}
                    value={divisionFilter}
                    onChange={e=>setDivisionFilter(e)}
                    size={"large"}
                    allowClear={true}
                    style={{width: 300}}
                    filterOption={true}
                    options={(divisionNames??[...(divisionFilter?[{title: '', id: divisionFilter}]:[])]).map(x=>({label: x.title, value: x.id}))}
                />
                <Button type={'primary'} size={"large"} onClick={()=>setPersonEdit({open: true})}>Add new person</Button>
                <div style={{flex: 1}}/>
                {rolesProvider.isAdmin() && <Button size={"large"} onClick={() => setImportOpen(true)}>Import</Button>}
                {
                    rolesProvider.isOrgAdmin() && <>
                        <Button size={"large"} onClick={() => refreshAll()}>Refresh all</Button>
                        <Popconfirm
                            title="Delete all persons"
                            description="Please note that it will also delete all manual entries and also all snapshots for these persons"
                            onConfirm={deleteAll}
                            okText="Yes"
                            cancelText="No">
                            <Button size={"large"}>Delete all</Button>
                        </Popconfirm>
                    </>
                }
                <Button size={"large"} onClick={downloadCSV}>Export CSV</Button>
                <Button size={"large"} type={'primary'} onClick={()=>setUploadOpen(true)}>Upload CSV</Button>
                {
                    rolesProvider.isAdmin() && <>
                        <Select<number>
                            allowClear={true}
                            style={{width: 200}}
                            placeholder={"Select snapshot"}
                            popupMatchSelectWidth={false}

                            options={snapshots.map(x=>({
                                label: <>{x.name} <i>{formatDate(x.created)}</i></>,
                                value: x.id
                            }))}
                            value={currentSnapshotId}
                            onSelect={x=>setCurrentSnapshotId(x)}
                            optionRender={optionRender} />
                        <Button size={"large"} onClick={()=>setSnapshotNameOpen(true)}>Create Snapshot</Button>
                    </>
                }
            </div>
            <div className={"page-content"}>
                {people!==undefined && <Table pagination={false} scroll={{y: '100%'}} dataSource={sortAndFilter(people, filter)} rowKey={'id'}>
                    <Column<PersonStatsDto> title={'Name'} dataIndex={'name'}
                            render={(text, record) =>
                                <a onClick={()=>setPersonEdit({open: true, id: record.id})}>{formatName(record)}</a>
                            }/>
                    <Column<PersonStatsDto> title={'Academic Rank'} dataIndex={'academicRank'}
                                            render={renderRank}/>
                    <Column<PersonStatsDto> title={'Division'}
                            render={(_, record: PersonDtoWithId) =>
                                renderDivisions(record)
                            }/>
                    <Column<PersonStatsDto> title={'Publications'} dataIndex={'count'}
                            render={(text, record) => <div style={{display: "flex", gap: 5}}>
                                {statuses[record.id] &&
                                    <>
                                        <DisplayCounters isAdmin={rolesProvider.isAdmin()}
                                                         record={statuses[record.id]}
                                                         setSelectedPerson={()=>setSelectedPerson(record)}
                                                         showHarvard={()=>showHarvard(record.id)}
                                                         showPubmed={()=>showPubmed(record.id)}
                                                         showV1={()=>showV1Diff(record.id)}
                                                         showSnapshot={()=>showSnapshot(record.id)}
                                        />
                                        {showRefreshStatus(record.id)}
                                    </>
                                }
                                {!statuses[record.id] &&
                                    <DisplayCounters isAdmin={rolesProvider.isAdmin()}
                                                     record={record}
                                                     setSelectedPerson={()=>setSelectedPerson(record)}
                                                     showHarvard={()=>showHarvard(record.id)}
                                                     showPubmed={()=>showPubmed(record.id)}
                                                     showV1={()=>showV1Diff(record.id)}
                                                     showSnapshot={()=>showSnapshot(record.id)}
                                    />
                                }
                                </div>
                            }/>
                </Table>}
            </div>
        </div>
    </Navigation>;
}