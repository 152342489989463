/* eslint-disable jsx-a11y/anchor-is-valid */
import {Input} from "antd";
import Column from "antd/es/table/Column";
import {useEffect, useState} from "react";
import {useApi} from "../ApiProvider"
import {FiltersDto, PersonYearsStatsDto, ProjectPIDto} from "../../generated/api";
import {CloseOutlined, SearchOutlined} from '@ant-design/icons';
import {formatName, numberSorter, rankSorter, textSorter} from "../../utils/utils";
import Highlighter from "react-highlight-words";
import {stopPropagationUtil} from "../../utils/clicks";
import {ShortRanks} from "../People";
import {TableWithCopy} from "../TableWithCopy";
import {ProjectPIPopup} from "./ProjectPIPopup";

interface ProjectsPIProps {
    filters: FiltersDto
    refreshKey?: number;
    metrics?: boolean
}

export function ProjectsPI(props: ProjectsPIProps) {
    const api = useApi()
    const [data, setData] = useState<ProjectPIDto[]>();
    const [selectedPerson, setSelectedPerson] = useState<PersonYearsStatsDto>()
    const [searchText, setSearchText] = useState('');

    useEffect(()=>{
        api.listProjectPIByYearAndDivision(props.filters).then(response=>{
            setData(response.data)
        })
    }, [props.filters, props.refreshKey, api])

    function filteredData(): ProjectPIDto[] | undefined {
        return data?.filter(x=>formatName(x).toLowerCase().includes(searchText.toLowerCase()));
    }

    function getNameColumn(width: string) {
        return <Column<PersonYearsStatsDto>
            width={width}
            title={
                <div className={"col-header"} onClick={e => stopPropagationUtil(e)}><span>Name</span>
                    <Input value={searchText} onChange={e => setSearchText(e.target.value)}
                           prefix={<SearchOutlined/>}
                           suffix={<a onClick={() => {
                               setSearchText("");
                           }}><CloseOutlined/></a>}
                    />
                </div>}
            sorter={textSorter('lastName')}
            render={(_, record) =>
                <a onClick={()=>setSelectedPerson(record)}>
                    <Highlighter
                        highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={formatName(record)}
                    />
                </a>
            }
        />;
    }


    return <div style={{flex: 1, display: 'flex', flexDirection: 'column', marginLeft: 20}}>
        <div className={"chart-title"}>Principal Investigators</div>
        <ProjectPIPopup person={selectedPerson} onClose={()=>setSelectedPerson(undefined)} />
        {data && <TableWithCopy size={"small"} scroll={{y: '100%'}} className={"people-publications-table"}
                                dataSource={filteredData()}
                                rowKey={'id'}
                                style={{flex: 1}} pagination={false}>
            {getNameColumn("30%")}
            <Column title={"Rank"}
                    dataIndex={"academicRank"}
                    sorter={rankSorter('academicRank')}
                    render={v => ShortRanks[v]}
            />
            <Column title={"Total Core Projects"}
                    dataIndex={"count"}
                    defaultSortOrder={'descend'}
                    sorter={numberSorter('count')}/>
        </TableWithCopy>}
    </div>
}