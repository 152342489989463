import * as React from "react";
import {useEffect, useState} from "react";
import {CloseOutlined} from "@ant-design/icons";
import {Button, Checkbox} from "antd";

interface PositionTagProps {
    onRemove: (event?: React.MouseEvent<HTMLElement, MouseEvent>)=>void
    value: string
    setValue: (value: string)=>void
}

export function PositionTag(props: PositionTagProps) {
    const positions = props.value.split(':')
    let is1stDefault = false
    let is2ndDefault = false
    let isLastDefault = false
    if (positions.length>1) {
        const posArray = positions[1].trim().split(',')
        if (posArray.includes('1st')) is1stDefault = true
        if (posArray.includes('2nd')) is2ndDefault = true
        if (posArray.includes('last')) isLastDefault = true
    }

    const [is1st, set1st] = useState(is1stDefault)
    const [is2nd, set2nd] = useState(is2ndDefault)
    const [isLast, setLast] = useState(isLastDefault)
    useEffect(()=>{

    }, [props.value])

    function setValue(intIs1st: boolean, intIs2nd: boolean, intIsLast: boolean) {
        props.setValue([intIs1st, intIs2nd, intIsLast].map((v,i)=>{
            if (!v) return undefined
            switch (i) {
                case 0: return '1st'
                case 1: return '2nd'
                default: return 'last'
            }
        }).filter(x=>x).join(','))
    }

    function set1stInt(val: boolean) {
        set1st(val)
        setValue(val, is2nd, isLast)
    }

    function set2ndInt(val: boolean) {
        set2nd(val)
        setValue(is1st, val, isLast)
    }

    function setLastInt(val: boolean) {
        setLast(val)
        setValue(is1st, is2nd, val)
    }

    return <div className={'tag'} onMouseDown={e=>e.stopPropagation()} style={{display: "flex", gap: 4, alignItems: "center"}}>
        <span style={{display: 'inline-block', padding: "0 4px 0 8px"}}>Position: </span>
        <Checkbox checked={is1st} onChange={e=>set1stInt(e.target.checked)}>1st</Checkbox>
        <Checkbox checked={is2nd} onChange={e=>set2ndInt(e.target.checked)}>2nd</Checkbox>
        <Checkbox checked={isLast} onChange={e=>setLastInt(e.target.checked)}>Last</Checkbox>
        <Button type={'text'} size={"small"} className={'close-tag'} onClick={props.onRemove}><CloseOutlined/></Button>
    </div>
}

