import {Navigation} from "./Navigation";
import {Button, Card, Table, Tabs} from "antd";
import {MeshDataDto, PubmedData, ReporterDataDto} from "../generated/api";
import React, {useEffect, useState} from "react";
import {useApi} from "./ApiProvider"
import Column from "antd/es/table/Column";

export function Settings() {
    const [data, setData] = useState<PubmedData[]>()
    const api = useApi()
    const [loading,setLoading] =useState(false);
    const [meshData, setMeshData] = useState<MeshDataDto>()
    const [reporterData, setReporterData] = useState<ReporterDataDto[]>()

    useEffect(()=> {
        api.getPubmedData().then(response=>{
            setData(response.data)
        })
        refreshMeshStatus()
    }, [api])

    function refreshMeshStatus() {
        api.getMeshData().then(response => {
            setMeshData(response.data)
        })
    }

    function refreshReporterStatus() {
        api.getReporterData().then(response => {
            setReporterData(response.data)
        })
    }

    return <Navigation breadcrumbs={[{title: 'Settings'}]}>
        <Tabs defaultActiveKey="settings" items={[
            {
                key: 'pubmed',
                label: 'PubMed',
                children: <>
                    <div style={{display: 'flex', gap: 10, marginBottom: 10}}>
                        <Button onClick={()=>api.downloadAll(false)}>
                            Download All
                        </Button>
                        <Button onClick={()=>api.downloadAll(true)}>
                            Force Download All
                        </Button>
                        <Button onClick={()=>api.downloadUpdates()}>
                            Download Updates
                        </Button>
                        <Button onClick={()=>api.downloadUpdates(true)}>
                            Force Download Updates
                        </Button>
                        <Button onClick={()=>{
                            setLoading(true);
                            api.getPubmedData().then(response=>{
                                setData(response.data)
                                setLoading(false);
                            })
                        }} loading={loading}>
                            Refresh
                        </Button>
                    </div>
                    <Table scroll={{y: 'calc(100vh - 240px)'}} pagination={false} dataSource={data}>
                        <Column title={"Path"} dataIndex={"path"} />
                        <Column title={"Started"} dataIndex={"started"} />
                        <Column title={"Finished"} dataIndex={"finished"} />
                        <Column title={"Status"} dataIndex={"status"} />
                    </Table>
                </>
            }, {
                key: 'mesh',
                label: 'MeSH',
                children: <>
                    <div style={{display: 'flex', gap: 10, marginBottom: 10}}>
                        <Button onClick={() => api.downloadMesh().then(()=>{
                            refreshMeshStatus()
                        })}>
                            Download MeSH
                        </Button>
                        <Button onClick={() => {
                            refreshMeshStatus()
                        }}>
                            Refresh
                        </Button>
                    </div>
                    <Card>
                    <div>Status: {meshData?.status??"N/A"}</div>
                    <div>Started: {meshData?.started??"N/A"}</div>
                    <div>Finished: {meshData?.finished??"N/A"}</div>
                    </Card>
                </>
            }, {
                key: 'reporter',
                label: 'RePORTER',
                children: <>
                    <div style={{display: 'flex', gap: 10, marginBottom: 10}}>
                        <Button onClick={() => api.downloadReporter(false).then(()=>{
                            refreshReporterStatus()
                        })}>
                            Download RePORTER
                        </Button>
                        <Button onClick={() => api.downloadReporter(true).then(()=>{
                            refreshReporterStatus()
                        })}>
                            Download RePORTER API
                        </Button>
                        <Button onClick={() => {
                            refreshReporterStatus()
                        }}>
                            Refresh
                        </Button>
                    </div>
                    {reporterData?.map(x=>
                        <Card key={x.type}>
                            <div>Type: {x?.type}</div>
                            <div>Status: {x?.status??"N/A"}</div>
                            <div>Started: {x?.started??"N/A"}</div>
                            <div>Finished: {x?.finished??"N/A"}</div>
                        </Card>
                    )}
                </>
            }
        ]}/>
    </Navigation>;
}