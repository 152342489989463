import {Button, Checkbox, Form, Input, Modal, ModalProps} from "antd";
import {useForm} from "antd/es/form/Form";
import {useApi} from "./ApiProvider";
import React, {useState} from "react";
import {ImportCatalystDto} from "../generated/api";

interface CatalystImportPopupProps extends ModalProps {
    onClose: (imported?: boolean)=>void
}

export function CatalystImportPopup(props: CatalystImportPopupProps) {
    const [form] = useForm()
    const api = useApi()
    const [updating, setUpdating] = useState(false);

    function startImport(values: ImportCatalystDto) {
        setUpdating(true);
        api.importCatalyst(values).then(()=>{
            props.onClose(true)
        }).finally(()=>{
            setUpdating(false);
        })
    }

    return <Modal
        {...props}
        title={"Import from Harvard Catalyst"}
        onCancel={()=>props.onClose()}
        footer={<div style={{display: 'flex'}}>
            <div className={"spacer"} />
            <Button key="cancel" onClick={()=>props.onClose()}>
                Cancel
            </Button>
            <Button key="submit" type="primary" loading={updating} onClick={form.submit}>
                Import
            </Button>
        </div>}
    >
        <Form<ImportCatalystDto> form={form} onFinish={startImport}
                                 colon={false}
                                 layout={"horizontal"}
                                 labelAlign={"right"}
                                 labelCol={{span: 6}}>
            <Form.Item label="Organization"
                       name={'organization'}
                       rules={[{ required: true, whitespace: true,  message: 'Required field' }]}>
                <Input />
            </Form.Item>
            <Form.Item label="Department"
                       name={'department'}
                       rules={[{ required: true, whitespace: true,  message: 'Required field' }]}>
                <Input />
            </Form.Item>
            <Form.Item label="Division"
                       name={'division'}>
                <Input />
            </Form.Item>
            <Form.Item name={'filterFellows'} label={" "}>
                <label><Checkbox value={"true"} /> Do not load Fellow/Post-Doc</label>
            </Form.Item>
            <Form.Item name={'useDivisions'} label={" "}>
                <label><Checkbox value={"true"} /> Use divisions information from HC</label>
            </Form.Item>
        </Form>
    </Modal>
}