import React, {useContext, useEffect, useState} from "react";
import {Navigation} from "./Navigation";
import {Button, Form, Input, Modal, Popconfirm, Select, Spin, Table} from "antd";
import Column from "antd/es/table/Column";
import {FilterOutlined} from "@ant-design/icons";
import {useApi} from "./ApiProvider"
import {ManagerCreateDto, ManagerDto} from "../generated/api";
import {useForm} from "antd/es/form/Form";
import {SettingsHolderContext} from "./SettingsHolder";

export function Managers() {
  const api = useApi()
  const settings = useContext(SettingsHolderContext)

  const rolesOptions=[
    {value: "VIEW", label: "View only"},
    {value: "MANAGE", label: "Allow edit"}
  ]

  const [createManagerForm] = useForm()
  const [data, setData] = useState<ManagerDto[]>();
  const [filter, setFilter] = useState("");
  const [managerAddOpen, setManagerAddOpen] = useState(false)
  const [loading, setLoading] = useState(false);


  function downloadData() {
    setLoading(true);
    api.listManagers().then(response => {
      setData(response.data)
      setLoading(false);
    })
  }
  useEffect(() => {
    downloadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api])

  function sortAndFilter(datasource: ManagerDto[], filter: string) {
    const lowercaseFilter = filter.toLowerCase();
    let ret = datasource.filter(x =>
        x.account.toLowerCase().includes(lowercaseFilter) ||
        x.firstName.toLowerCase().includes(lowercaseFilter) ||
        x.lastName.toLowerCase().includes(lowercaseFilter) ||
        x.email.toLowerCase().includes(lowercaseFilter)
    )
    ret.sort((a, b) => a.account.localeCompare(b.account, undefined, {sensitivity: 'base'}))
    return ret
  }

  function deleteManager(id: string) {
    api.deleteManager(id).then(()=>{
      downloadData();
    })
  }

  function isEmailSuffix() {
    return settings?.settings?.authSuffix?.startsWith("@")
  }

  function removeSuffix(account: string) {
    if (settings?.settings?.authSuffix && !isEmailSuffix()) {
      if (account.endsWith("@" + settings?.settings?.authSuffix)) {
        return account.split('@')[0]
      }
    }
    return account;
  }

  function saveManager(values: ManagerCreateDto) {
    api.createManager({
          ...values,
          account: removeSuffix(values.account)
        },
    ).then(()=>{
      downloadData();
      setManagerAddOpen(false)
      createManagerForm.resetFields();
    })
  }

  return <Navigation breadcrumbs={[{title: 'Managers List'}]}>
    <Modal
        title={"Add new manager"}
        open={managerAddOpen}
        onCancel={()=>setManagerAddOpen(false)}
        onOk={()=>{createManagerForm.submit()}}
    >
      <Form<ManagerCreateDto> form={createManagerForm}
                              initialValues={{role: 'VIEW'}}
                         colon={false}
                         layout={"horizontal"}
                         labelAlign={"right"}
                         labelCol={{span: 6}}
                         onFinish={values=>{
                           saveManager(values)
                         }}
                         style={{paddingTop: 10}}>
        <Form.Item label={isEmailSuffix()?"User Email":"User ID"}
                   name={'account'}
                   rules={[
                       { required: true, whitespace: true,  message: 'Required field' },
                       {
                         message: 'SSO only allows users with ' + settings?.settings?.authSuffix + ' email',
                         validator: (_, value) => {
                           if (!value) return Promise.resolve();
                           if (!isEmailSuffix()) return Promise.resolve();
                           if (!value.endsWith(settings?.settings?.authSuffix)) {
                             return Promise.reject('SSO only allows users with ' + settings?.settings?.authSuffix + ' email');
                           } else {
                             return Promise.resolve()
                           }
                         }
                       },
                       {
                         message: 'User already exists',
                         validator: (_, value) => {
                           if (data?.filter(x=>x.account.toLowerCase()===removeSuffix(value).toLowerCase()).length) {
                             return Promise.reject('User already exists');
                           } else {
                             return Promise.resolve();
                           }
                         }
                       }]}>
          <Input />
        </Form.Item>
        <Form.Item label={"Role"}
                   name={'role'}>
          <Select options={rolesOptions}/>
        </Form.Item>
      </Form>
    </Modal>
    <div className={"page-with-sub-header"}>
      <div className={"sub-header"}>
        <Input value={filter} onChange={e => setFilter(e.target.value)} size="large"
               placeholder="filter by name" prefix={<FilterOutlined/>} style={{width: 200}}/>
        <Button type={'primary'} size={"large"} onClick={() => setManagerAddOpen(true)}>Add new
          manager</Button>
      </div>
      <Spin spinning={loading} />
      {data !== undefined &&
        <Table pagination={false} scroll={{y: '100%'}} dataSource={sortAndFilter(data, filter)}
               rowKey={'id'}>
          <Column title={isEmailSuffix()?'Email':'Account'} dataIndex={'account'}/>
          <Column title={'Name'} render={x=>
            <>
              {(!!x?.lastName || !!x?.firstName) && <>{x?.lastName}, {x?.firstName}</>}
              {(!x?.lastName && !x?.firstName) && <><i>User is not yet logged in</i></>}
            </>}/>
          {!isEmailSuffix() && <Column title={'Email'} dataIndex={'email'}/>}
          <Column<ManagerDto> title={'Role'}
                              render={row=>
                                  <Select onChange={value=>{
                                            api.changeManagerRole(row.id, value)
                                                .then(()=>{
                                                  setData(old=>{
                                                    if (!old) return undefined
                                                    let newData=[...old]
                                                    newData.filter(x=>x.id===row.id).forEach(x=>x.role=value)
                                                    return newData
                                                  })
                                                })
                                            }}
                                            value={row.role}
                                            options={rolesOptions} />
                              }
          />
          <Column<ManagerDto> title={'Actions'}
                render={x=>
                  <Popconfirm
                      title="Delete manager"
                      description="Are you sure to delete this manager?"
                      onConfirm={()=>deleteManager(x.id)}
                      okText="Yes"
                      cancelText="No"
                  >
                    <Button key="delete" danger={true}>
                      Delete
                    </Button>
                  </Popconfirm>}
          />
        </Table>}
    </div>
  </Navigation>
}