import React, {useContext, useEffect} from 'react';
import './App.scss';
import {ReactKeycloakProvider} from "@react-keycloak/web";
import {createBrowserRouter, RouterProvider, useNavigate, useParams} from "react-router-dom";
import Keycloak, {KeycloakInitOptions} from "keycloak-js";
import {People} from "./People";
import {Settings} from "./Settings";
import {Logout} from "./Logout";
import {Divisions} from "./Divisions";
import {Spin} from "antd";
import {Clusters} from "./Clusters";
import {Managers} from "./Managers";
import {Institutions} from "./Institutions";
import {SettingsHolder, SettingsHolderContext} from "./SettingsHolder";
import ReactGA from "react-ga4";
import {useRolesProvider} from "../utils/keycloakUtils";
import {hotjar} from "react-hotjar";
import {init} from "@sentry/react"
import {ApiProvider} from "./ApiProvider";
import {Analytics} from "./Analytics";
import {Reports} from "./Reports";
import {SingleReport} from "./SingleReport";
import {Projects} from "./projects/Projects";

function InstSelector() {
  const { inst } = useParams();
  const navigate = useNavigate();
  const settingsHolder = useContext(SettingsHolderContext)
  const rolesProvider = useRolesProvider()
  useEffect(()=>{
    if (inst) {
      if (rolesProvider.hasAccessTo(inst)) {
        settingsHolder?.setInst(inst)
        navigate("/")
      }
    }
  }, [])
  if (inst) {
    if (!rolesProvider.hasAccessTo(inst)) {
      return <>You do not have access to this organization. <a href={"/"}>Click here</a> to get back</>
    }
  }
  return <Spin />
}

function SingleReportSelector() {
  const { inst, period, date } = useParams();
  const navigate = useNavigate();
  const settingsHolder = useContext(SettingsHolderContext)
  const rolesProvider = useRolesProvider()
  useEffect(()=>{
    if (inst) {
      if (rolesProvider.hasManagerAccessTo(inst)) {
        settingsHolder?.setInst(inst)
        navigate("/reports/" + period + "/" + date)
      }
    }
  }, [])
  if (inst) {
    if (!rolesProvider.hasManagerAccessTo(inst)) {
      return <>You do not have MANAGER access to this organization. <a href={"/"}>Click here</a> to get back</>
    }
  }
  return <Spin />
}

function App() {
  const keycloak = new Keycloak({
    url: process.env.REACT_APP_URL,
    realm: process.env.REACT_APP_REALM??"faculty",
    clientId: process.env.REACT_APP_CLIENT_ID??"faculty-app",
  })

  const initOptions: KeycloakInitOptions = { pkceMethod: 'S256' }

  const loadingComponent = (
      <div className={"loading"}><Spin/></div>
  )

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Analytics />
    }, {
      path: "/select/:inst",
      element: <InstSelector />
    }, {
      path: "/projects",
      element: <Projects />
    }, {
      path: "/people",
      element: <People />
    }, {
      path: "/reports",
      element: <Reports />
    }, {
      path: "/reports/:period/:date",
      element: <SingleReport />
    }, {
      path: "/report/:inst/:period/:date",
      element: <SingleReportSelector />
    }, {
      path: "/divisions",
      element: <Divisions />
    }, {
      path: "/clusters",
      element: <Clusters />
    }, {
      path: "/settings",
      element: <Settings />
    }, {
      path: "/managers",
      element: <Managers />
    }, {
      path: "/institutions",
      element: <Institutions />
    }, {
      path: "/logout",
      element: <Logout keycloak={keycloak} />
    }
  ]);

  return <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={initOptions}
      LoadingComponent={loadingComponent}
      isLoadingCheck={keycloak => !keycloak.authenticated}
      onEvent={(event, error) => {
        if (error) {
          console.log(error)
          keycloak.login()
        }
        if (event === 'onAuthRefreshError') {
          keycloak.login()
        }
        if (event === 'onAuthSuccess') {
          if (process.env.REACT_APP_GA_TAG) {
            ReactGA.initialize(process.env.REACT_APP_GA_TAG, {
              gaOptions: {
                user_id: keycloak.tokenParsed?.preferred_username,
                // authUsername: keycloak.tokenParsed?.preferred_username,
                // authUserId: keycloak.tokenParsed?.preferred_username.replaceAll(/[@.]/g, "_")
              }
            })
            ReactGA.set({
              user_properties: {
                authUsername: keycloak.tokenParsed?.preferred_username,
                authUserId: keycloak.tokenParsed?.preferred_username.replaceAll(/[@.]/g, "_")
              }
            })
          }
          // do not send for admin!
          if (process.env.REACT_APP_HOT_JAR_KEY && !keycloak.tokenParsed?.realm_access?.roles.includes('ADMIN')) {
            const hj = process.env.REACT_APP_HOT_JAR_KEY.split("-")
            hotjar.initialize(Number(hj[0]), Number(hj[1]))
            hotjar.identify(keycloak.tokenParsed?.preferred_username,
                {
                  authUsername: keycloak.tokenParsed?.preferred_username,
                  authUserId: keycloak.tokenParsed?.preferred_username.replaceAll(/[@.]/g, "_")
                })
          }

          if (process.env.REACT_APP_SENTRY_DSN) {
            init({
              dsn: process.env.REACT_APP_SENTRY_DSN,
              integrations: [],
            })
          }
        }
        if (event === 'onReady') {
          if (!keycloak.authenticated) keycloak.login()
        }
      }}
  >
    <SettingsHolder keycloak={keycloak}>
      <ApiProvider keycloak={keycloak}>
        <RouterProvider router={router}/>
      </ApiProvider>
    </SettingsHolder>
  </ReactKeycloakProvider>
}

export default App;
