import {Breadcrumb, Divider, Dropdown, Input, Layout, Menu, Space, theme} from "antd";
import React, {PropsWithChildren, useCallback, useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Content, Header} from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import './Navigation.scss'
import {ItemType} from "antd/es/menu/hooks/useItems";
import {BreadcrumbItemType} from "antd/es/breadcrumb/Breadcrumb";
import {ErrorBoundary} from "./ErrorBoundary";
import {InstitutionDto, SettingsDto} from "../generated/api";
import {useApi} from "./ApiProvider"
import {useRolesProvider} from "../utils/keycloakUtils";
import {SettingsHolderContext} from "./SettingsHolder";
import ReactGA from "react-ga4";
import {hotjar} from "react-hotjar";
import {handleGlobalClick} from "../utils/clicks";
import Highlighter from "react-highlight-words";
import {
    BankOutlined,
    CrownOutlined, DollarOutlined,
    FundOutlined,
    LogoutOutlined,
    PartitionOutlined,
    PieChartOutlined,
    SearchOutlined,
    SettingOutlined,
    TeamOutlined
} from "@ant-design/icons";

interface NavigationProps {
    breadcrumbs: BreadcrumbItemType[],
    rawBreadcrumbs?: boolean;
    title?: string
}

export const SettingsContext = React.createContext<SettingsDto | undefined>(undefined)

export function Navigation(props: PropsWithChildren<NavigationProps>) {
    const navigate = useNavigate()
    const location = useLocation()
    const settingsHolder = useContext(SettingsHolderContext)

    const [logo, setLogo] = useState<string>();
    const [filter, setFilter] = useState<string>()
    const [institutions, setInstitutions] = useState<InstitutionDto[]>([])

    const rolesProvider = useRolesProvider();

    const api = useApi();

    const token = theme.useToken().token;

    const settings = settingsHolder?.settings;

    useEffect(() => {
        document.title = "Faculty Analytics | " + (props.title??props.breadcrumbs[props.breadcrumbs.length-1].title)
        if (ReactGA.isInitialized) {
            ReactGA.send("pageview");
        }
        if (hotjar.initialized()) {
            hotjar.stateChange(document.location.pathname);
        }
    }, [location]);

    useEffect(()=>{
        setLogo(settings?.logo)
    }, [api, settingsHolder?.inst, settings?.logo])

    useEffect(()=>{
        if (rolesProvider.hasMultipleOrgs()) {
            api.listInstitutionNames().then(result=>setInstitutions(result.data))
        }
    }, [api])

    function highlight(name: string) {
        if (!filter) return name;
        return <Highlighter
            highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
            searchWords={[filter]}
            autoEscape
            textToHighlight={name}
        />;
    }

    const dropdownRender = useCallback((menu: React.ReactNode) => (
        <div style={{
            backgroundColor: token.colorBgElevated,
            borderRadius: token.borderRadiusLG,
            boxShadow: token.boxShadowSecondary,
        }}>
            <Space style={{ padding: 8, display: 'flex' }} styles={{item: {flex: 1}}}>
                <Input prefix={<SearchOutlined/>} value={filter} onChange={e=>setFilter(e.target.value)} />
            </Space>
            <Divider style={{ margin: 0 }} />
            {React.cloneElement(menu as React.ReactElement, {style: {boxShadow: 'none'}})}
        </div>
    ), [filter, token])

    function getBreadcrumbs() {
        if (props.rawBreadcrumbs) return props.breadcrumbs

        if (!settings) return []
        if (settings.name) {
            return [{
                title:
                    (rolesProvider.hasMultipleOrgs() ?
                        <Dropdown menu={{
                                            items: institutions.filter(x=>!filter || x.name.toLowerCase().includes(filter.toLowerCase())).map(x=>({
                                                key: x.inst,
                                                label: <a href={"#"} onClick={e=>{e.preventDefault(); navigate("/select/" + x.inst)}}>{highlight(x.name)}</a>
                                            }))}}
                                  trigger={["click"]}
                                  rootClassName={'inst-popup'}
                                  dropdownRender={dropdownRender}

                        >
                            <a href={"#"} style={{height: 'auto'}}
                            onClick={e => {e.preventDefault()/*navigate("/institutions")*/}}
                            >{settings.name}</a>
                        </Dropdown>

                        : settings.name)
            } , ...props.breadcrumbs]
        } else {
            return [ {title: 'Faculty Analytics' }, ...props.breadcrumbs]
        }
    }

    function menuClick(e: {key: string}) {
        navigate(e.key)
    }

    function buildNavigation(): ItemType[] {
        let items: ItemType[] = [];
        if (settingsHolder?.inst!=="NONEXISTENT") {
            // items.push({
            //     label: 'Publications',
            //     key: '/',
            //     icon: <PieChartOutlined/>
            // })
            items.push({
                label: 'Publications',
                key: '/',
                icon: <PieChartOutlined/>
            })
            if (rolesProvider.isManager()) {
                items.push({
                    label: 'Reports',
                    key: '/reports',
                    icon: <FundOutlined/>
                })
                items.push({
                    label: <>NIH Projects <span style={{position: 'relative'}}><i style={{ fontSize: 11, position: 'absolute', top: -14, left: 3 }}>beta</i></span></>,
                    key: '/projects',
                    icon: <DollarOutlined/>
                })
                items.push({
                    type: 'divider',
                    key: 'divider',
                    style: {
                        flex: 0,
                        margin: '5px 10px',
                        borderTop: 'solid 1px #aaa'
                    }
                })
                items.push({
                    label: 'Manage Divisions',
                    key: '/divisions',
                    icon: <PartitionOutlined/>
                })
                items.push({
                    label: 'Manage People',
                    key: '/people',
                    icon: <TeamOutlined/>
                })
                // items.push({
                //     label: 'Clusters',
                //     key: '/clusters',
                //     icon: <RadarChartOutlined />
                // })
            }
        }
        items.push({
            type: 'divider'
        })
        if (rolesProvider.isManager() && settingsHolder?.settings?.authSuffix) {
            items.push({
                label: 'Managers',
                key: '/managers',
                icon: <CrownOutlined/>
            })
        }
        if (rolesProvider.isAdmin()) {
            items.push({
                label: 'Settings',
                key: '/settings',
                icon: <SettingOutlined />
            })
        }
        if (rolesProvider.isOrgAdmin() || rolesProvider.hasMultipleOrgs()) {
            items.push({
                label: 'Organizations',
                key: '/institutions',
                icon: <BankOutlined/>
            })
        }
        items.push({
            label: 'Logout',
            key: '/logout',
            icon: <LogoutOutlined />
        })
        return items
    }
    const items: ItemType[] = buildNavigation()

    let selected = items.find(x=>x?.key && x.key!=="/" && location.pathname.startsWith(x.key.toString()))?.key?.toString()??"/"

    return <Layout onClick={handleGlobalClick}>
        <ErrorBoundary fallback={"Something went wrong"}>
        <Sider>
            <a href={"/"} className="logo" onClick={e=>{
                navigate("/")
                e.preventDefault()
            }}/>
            <Menu theme="dark"
                  onClick={menuClick}
                  selectedKeys={[selected]}
                  mode="inline"
                  items={items}
            />
        </Sider>
        <Layout>
            <Header className={"breadcrumb-header"}>
                <Breadcrumb items={getBreadcrumbs()}/>
                {!!logo && <img alt='' src={logo}/>}
            </Header>
            <SettingsContext.Provider value={settings}>
                <Content style={{ margin: '16px 16px 8px' }}>
                    {props.children}
                </Content>
            </SettingsContext.Provider>
        </Layout>
        </ErrorBoundary>
    </Layout>
}