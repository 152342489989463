import React, {ReactNode, useEffect, useMemo, useState} from "react";
import {SettingsDto} from "../generated/api";
import {getApi} from "./ApiProvider"
import {Button} from "antd";
import Keycloak from "keycloak-js";
import './SettingsHolder.scss'

export interface SettingsHolderInterface {
    settings: SettingsDto | undefined;
    setSettings(settings: SettingsDto): void;
    inst: string | undefined;
    setInst(value: string): void;
}

export const SettingsHolderContext = React.createContext<SettingsHolderInterface | null>(null)

export function SettingsHolder(props: {keycloak: Keycloak, children: ReactNode}) {
    const [settings, setSettings] = useState<SettingsDto>()
    const [inst, setInstState] = useState<string>()

    function getCurrentInst(provided: string[]) {
        if (!provided.length) return "NONEXISTENT"
        const saved = localStorage.getItem("defaultInst")
        if (saved && provided.includes(saved)) return saved
        return provided[0]

    }

    const isAdmin = props.keycloak.realmAccess?.roles.includes("ADMIN")
    const isOrgAdmin = props.keycloak.realmAccess?.roles.includes("ORG_ADMIN")

    useEffect(()=>{
        // we cannot use standard useApi/useAdminApi here since they are initialized later
        if (inst) {
            if (inst!=="NONEXISTENT") {
                getApi(props.keycloak, inst).getSettings().then(response => setSettings(response.data));
            }
        } else {
            if (isAdmin || isOrgAdmin) {
                getApi(props.keycloak).getAllInstIds().then(response=> {
                    setInstState(getCurrentInst(response.data))
                });
            } else {
                const provided = props.keycloak.realmAccess?.roles.filter(x => x.includes("/"))
                    .map(x => x.split("/")[0]) ?? []
                setInstState(getCurrentInst(provided))
            }
        }
    }, [inst, isAdmin, isOrgAdmin, props.keycloak])

    function setInst(val: string) {
        setInstState(val)
        localStorage.setItem("defaultInst", val);
    }

    const settingsMemo = useMemo(()=>{
        return {settings, setSettings, inst, setInst}
    }, [settings, inst])

    return <SettingsHolderContext.Provider value={settingsMemo}>
        {inst==="NONEXISTENT" && !isAdmin && <div className={"access-denied-main"}>
            <div className={"logo_dark"} />
            <div>Access Denied. <a href={"https://www.facultyanalytics.com/demo"}>Click here</a> to request access.</div>
            <div className={"logout"}><Button type={"default"} onClick={()=>props.keycloak.logout()}>Logout</Button></div>
        </div>}
        {(inst !== "NONEXISTENT" || isAdmin) && <>
            {settings?props.children:undefined}
            {/*TODO: initial state if no institutions defined*/}
            {/*{(settings || isAdmin)?props.children:undefined}*/}
        </>}
    </SettingsHolderContext.Provider>
}