import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {Button, Checkbox, Popover, Segmented, Space} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import {SettingsContext} from "../Navigation";

interface YearTagProps {
    closeSelectDropdown: ()=>void
    onRemove: (event?: React.MouseEvent<HTMLElement, MouseEvent>)=>void
    value: string
    setValue: (value: string)=>void
    yearsOpen: boolean
    setYearsOpen: (open: boolean)=>void
    showAcademic: boolean
}

interface YearsBlockProps {
    startYear: number
    selectedYear?: number
    onChangeYear: (year: number)=>void
}

export function YearsBlock(props: Readonly<YearsBlockProps>) {
    const currentYear = useMemo(()=>new Date().getFullYear(), [])
    const ranges = useMemo(()=>{
        const rounded = Math.floor(props.startYear/10)*10
        let ret = []
        for (let i=rounded; i<currentYear; i+=10) {
            ret.push(i.toString()+"-"+Math.min(i+9, currentYear).toString())
        }
        return ret
    }, [props.startYear, currentYear])
    const [currentRange, setCurrentRange] = useState<string | number>(ranges[ranges.length-1])

    useEffect(()=>{
        for (const element of ranges) {
            if (props.selectedYear &&
                props.selectedYear >= Number(element.split('-')[0]) &&
                props.selectedYear <= Number(element.split('-')[1])) {
                setCurrentRange(element)
            }
        }
    }, [props.selectedYear])

    function renderYears(st: number, en: number) {
        let items: React.ReactElement[] = []
        for (let i=st; i<=en; i++) {
            items.push(<Button type={(i===props.selectedYear && props.selectedYear>=props.startYear)?'primary':'default'}
                               onClick={()=>props.onChangeYear(i)}
                               disabled={i<props.startYear}
            >{i}</Button>)
        }
        return items
    }

    function renderGroups() {
        let items: React.ReactElement[] = []
        const cr = currentRange.toString().split('-')
        const st = Number(cr[0])
        const en = Number(cr[1])
        items.push(<Space>{renderYears(st, Math.min(st+4, en))}</Space>)
        if (en-st>=5) {
            items.push(<Space>{renderYears(st+5, Math.min(st+9, en))}</Space>)
        }
        return items
    }
    return <>
        <Segmented options={ranges} value={currentRange} onChange={setCurrentRange}/>
        <Space direction={'vertical'} style={{display: 'flex', marginTop: 10}}>
            {renderGroups()}
        </Space>
    </>
}

export function YearsTag(props: YearTagProps) {
    const currentYear = useMemo(()=>new Date().getFullYear(), [])
    const [singleOrRange, setSingleOrRange] = useState('single')
    const [startYear, setStartYear] = useState<number>()
    const [endYear, setEndYear] = useState<number>()
    const [academic, setAcademic] = useState(false)
    useEffect(()=>{
        if (props.value.startsWith("year:")) {
            const years = props.value.split(':', 2)[1].trim()
            const yearsAcademic = years.split(',');
            const yearsArray = yearsAcademic[0].split('-')
            setStartYear(Number(yearsArray[0]))
            if (yearsArray.length>1) {
                setSingleOrRange('range')
                setEndYear(Number(yearsArray[1]))
            }
            if (yearsAcademic.length>1) {
                setAcademic(true)
            }
        }
    }, [props.value])

    function updateValue(start: number, end?: number, academic?: boolean) {
        const academicSuffix = academic?",academic":""
        if (singleOrRange==='single' && start) {
            props.setValue(start.toString() + academicSuffix)
        }
        if (singleOrRange==='range' && start && end) {
            props.setValue(start.toString()+"-"+end.toString() + academicSuffix)
        }
    }

    function getButtonTitle() {
        if (!props.value || props.value==='year') return "Choose years"
        const year = props.value.split(':', 2)[1].trim()
        const academic = year.split(',')
        if (academic.length>1) {
            return "Academic year(s): " + academic[0]
        } else {
            return academic[0];
        }
    }

    function setInt(start: number, end: number, academic: boolean) {
        setStartYear(start)
        setEndYear(end)
        setAcademic(academic)
        updateValue(start, end, academic)
        props.setYearsOpen(false)
    }

    return <SettingsContext.Consumer>
        {settings=>
            <div className={'tag'} onMouseDown={e=>e.stopPropagation()}>
                <Popover content={<>
                    <Segmented options={[
                        {label: 'Single Year', value: 'single'}, {label: 'Years Range', value: 'range'}
                    ]} onChange={(e)=>{
                        setSingleOrRange(e.toString())
                    }} value={singleOrRange} />
                    {singleOrRange==='range' && <>
                        <div>
                            <Space>
                                <div style={{fontWeight: 'bold', fontSize: 12, margin: '8px 6px'}}>Quick links:</div>
                                <Button type={"link"} size={"small"} onClick={()=>setInt(currentYear-2, currentYear, false)}>Last 3 years</Button>
                                <Button type={"link"} size={"small"} onClick={()=>setInt(currentYear-4, currentYear, false)}>Last 5 years</Button>
                                <Button type={"link"} size={"small"} onClick={()=>setInt(currentYear-9, currentYear, false)}>Last 10 years</Button>
                            </Space>
                        </div>
                        <div style={{fontWeight: 'bold', fontSize: 12, margin: '8px 6px'}}>Start year</div>
                        <YearsBlock startYear={settings?.startYear??2000} selectedYear={startYear} onChangeYear={(year)=>{
                            setStartYear(year)
                            if (endYear && endYear<year) setEndYear(undefined)
                        }} />
                        {!!startYear && <>
                            <div style={{fontWeight: 'bold', fontSize: 12, margin: '8px 6px'}}>End year</div>
                            <YearsBlock startYear={startYear??settings?.startYear??2000} selectedYear={endYear} onChangeYear={(year)=>{
                                setEndYear(year)
                                updateValue(startYear, year, academic)
                                props.setYearsOpen(false)
                            }}/>
                        </>}
                    </>}
                    {singleOrRange==='single' && <>
                        <div style={{height: 10}}></div>
                        <YearsBlock startYear={settings?.startYear??2000} selectedYear={startYear} onChangeYear={(year)=>{
                            setStartYear(year)
                            updateValue(year, undefined, academic)
                            props.setYearsOpen(false)
                        }}/>
                    </>}
                    {/*TODO: define start/end date*/}
                    {props.showAcademic &&
                    <div style={{marginTop: 10}}>
                        <Checkbox checked={academic} onChange={e=>{
                            setAcademic(e.target.checked)
                            if (startYear && (endYear || singleOrRange==='single')) {
                                updateValue(startYear, endYear, academic)
                            }
                        }}>Academic years</Checkbox>
                    </div>}
                </>}
                         arrow={false}
                         trigger={'click'}
                         placement={"bottomLeft"}
                         destroyTooltipOnHide={true}
                         align={{offset: [0, 6]}}
                         onOpenChange={(open)=>{
                             if (open) {
                                 props.closeSelectDropdown()
                             } else {
                                 if (startYear) {
                                     updateValue(startYear, endYear, academic)
                                 }
                             }
                             props.setYearsOpen(open)
                         }}
                         open={props.yearsOpen}
                >
                    <Button size={"small"} type={'link'}>{getButtonTitle()}</Button>
                </Popover>
                <Button type={'text'} size={"small"} className={'close-tag'} onClick={props.onRemove}><CloseOutlined/></Button>
            </div>}
    </SettingsContext.Consumer>
}

