import {Button, Checkbox, ColorPicker, Form, Input, Modal, ModalProps, Popconfirm} from "antd";
import React, {useEffect, useState} from "react";
import {useApi} from "./ApiProvider"
import {DivisionDto, DivisionDtoWithId} from "../generated/api";
import {useForm} from "antd/es/form/Form";
import {checkYear} from "../utils/utils";

interface DivisionEditProps extends ModalProps {
    divisionId?: number;
    onClose: (updated?: DivisionDtoWithId | null)=>void;
}

interface ColorInputProps {
    value?: string;
    onChange?: (value: string) => void;
}

const ColorInput: React.FC<ColorInputProps> = ({ value , onChange }) => {
    return <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
        <Checkbox checked={!!value} onChange={e=>e.target.checked?onChange?.(Math.floor(Math.random()*16777215).toString(16)):onChange?.("")}>Use preferred color</Checkbox>
        {value && <ColorPicker trigger={'hover'} value={value} onChange={e=>onChange?.(e.toHex())} />}
    </div>
}

export function DivisionEdit(props: DivisionEditProps) {
    const [form] = useForm()
    const [updating, setUpdating] = useState(false);
    const api = useApi()
    
    useEffect(()=> {
        if (props.open) {
            form.resetFields()
            if (props.divisionId) {
                api.getDivision(props.divisionId).then(response => form.setFieldsValue(response.data))
            }
            setUpdating(false)
        }
    }, [form, api, props.divisionId, props.open])

    function saveObject(values: DivisionDto) {
        setUpdating(true);
        const promise = props.divisionId ? api.updateDivision(props.divisionId, values): api.createDivision(values)

        promise.then(response=>{
            setUpdating(false);
            props.onClose(response.data)
        })
    }

    function deleteObject() {
        if (props.divisionId) {
            api.deleteDivision(props.divisionId).then(() => {
                props.onClose(null)
            })
        }
    }

    return <Modal
        {...props}
        title={props.divisionId?"Update division":"New division"}
        onCancel={()=>props.onClose()}
        footer={<div style={{display: 'flex'}}>
            {!!props.divisionId &&
                <Popconfirm
                    title="Delete the division"
                    description="Are you sure to delete this division?"
                    onConfirm={deleteObject}
                    okText="Yes"
                    cancelText="No"
                >
                <Button key="delete" danger={true}>
                Delete
                </Button>
            </Popconfirm>}
            <div className={"spacer"} />
            <Button key="cancel" onClick={()=>props.onClose()}>
                Cancel
            </Button>
            <Button key="submit" type="primary" loading={updating} onClick={form.submit}>
                Save
            </Button>
        </div>}
    >
        <Form<DivisionDto> form={form}
                           colon={false}
                           layout={"horizontal"}
                           labelAlign={"right"}
                           labelCol={{span: 6}}
                           onFinish={saveObject}
                           style={{paddingTop: 10}}>
            <Form.Item label="Title"
                       name={'title'}
                       rules={[{ required: true, whitespace: true,  message: 'Required field' }]}>
                <Input />
            </Form.Item>
            <Form.Item label="Color" name={"color"}>
                <ColorInput/>
            </Form.Item>
            <Form.Item label="Years">
                <div style={{display: "flex", gap: 10}}>
                    <Form.Item name={'startYear'} validateTrigger={'onBlur'} rules={[{validator: (_, v)=>checkYear(v)}]} style={{margin: 0, width: 100}}>
                        <Input placeholder={"Start"} />
                    </Form.Item>
                    <div style={{lineHeight: 2}}>&ndash;</div>
                    <Form.Item  name={'endYear'} validateTrigger={'onBlur'} rules={[{validator: (_, v)=>checkYear(v)}]} style={{margin: 0, width: 100}}>
                        <Input placeholder={"End"} />
                    </Form.Item>
                </div>
            </Form.Item>
        </Form>
    </Modal>
}