import {ProjectFiltersDto} from "../../generated/api";
import {ProjectList} from "./ProjectList";
import {Flex} from "antd";
import {ProjectsPI} from "./ProjectsPI";
import {ProjectsBudgetChartYears} from "./ProjectsBudgetChartYears";

interface ProjectLayoutProps {
    filters: ProjectFiltersDto
    refreshKey: number
}

export function ProjectsBudgetLayout(props: Readonly<ProjectLayoutProps>) {
    return <div style={{display: "flex", flex: 1}} className={"analytics-page"}>
        <Flex vertical={true}>
            <Flex flex={1}>
                <ProjectsBudgetChartYears filters={props.filters} refreshKey={props.refreshKey}/>
                <ProjectsPI filters={props.filters} refreshKey={props.refreshKey}/>
            </Flex>
            <ProjectList filters={props.filters} refreshKey={props.refreshKey} withBudgets={true}/>
        </Flex>
    </div>
}