import {SyntheticEvent} from "react";
import {hotjar} from "react-hotjar";

export function handleGlobalClick(_: SyntheticEvent) {
    if (hotjar.initialized()) {
        hotjar.event('click')
    }
}

export function stopPropagationUtil(e: SyntheticEvent) {
    e.stopPropagation();
    handleGlobalClick(e);
}