/* eslint-disable jsx-a11y/anchor-is-valid */
import {Navigation} from "./Navigation";
import {Button, Input, Table} from "antd";
import {FilterOutlined} from '@ant-design/icons';
import Column from "antd/es/table/Column";
import {DivisionEdit} from "./DivisionEdit";
import {useContext, useEffect, useState} from "react";
import {DivisionDto, DivisionDtoWithId, DivisionStatsDto} from "../generated/api";
import {useApi} from "./ApiProvider"
import {useNavigate} from "react-router-dom";
import {useRolesProvider} from "../utils/keycloakUtils";
import {SettingsHolderContext} from "./SettingsHolder";

export function Divisions() {

    const [divisionEdit, setDivisionEdit] = useState<{ open: boolean, id?: number }>({open: false})
    const [datasource, setDatasource] = useState<DivisionStatsDto[]>();
    const [filter, setFilter] = useState("");

    const api = useApi()

    const rolesProvider = useRolesProvider()

    const navigate = useNavigate();
    const settingsHolder = useContext(SettingsHolderContext)

    if (settingsHolder?.inst==="NONEXISTENT") {
        navigate("/institutions")
    }


    useEffect(() => {
        api.listDivisions().then(response => setDatasource(response.data))
    }, [api])

    function sortAndFilter(datasource: DivisionDtoWithId[], filter: string) {
        const filterLowercase = filter.toLowerCase();
        let ret = datasource.filter(x => x.title.toLowerCase().includes(filterLowercase))
        ret.sort((a, b) => a.title.localeCompare(b.title, undefined, {sensitivity: 'base'}))
        return ret
    }

    function navigatePeople(id: number, _title: string) {
        navigate("/people", {state: {id: id}})
    }

    function deleteAll() {
        api.deleteAllDivisions().then();
    }

    return <Navigation breadcrumbs={[{title: 'Manage Divisions'}]}>
        <DivisionEdit open={divisionEdit.open}
                      divisionId={divisionEdit.id}
                      onClose={(updated) => {
                          if (updated !== undefined) {
                              setDatasource((old) => {
                                      const filtered = (old ?? []).filter(x => !divisionEdit.id || x.id !== divisionEdit.id)
                                      return updated ?
                                          [...filtered,
                                              {
                                                  ...updated,
                                                  count: (old ?? []).find(x => x.id === divisionEdit.id)?.count ?? 0
                                              }
                                          ] : filtered
                                  }
                              )
                          }
                          setDivisionEdit({open: false})
                      }}
        />
        <div className={"page-with-sub-header"}>
            <div className={"sub-header"}>
                <Input value={filter} onChange={e => setFilter(e.target.value)} size="large"
                       placeholder="filter by name" prefix={<FilterOutlined/>} style={{width: 200}}/>
                <Button type={'primary'} size={"large"} onClick={() => setDivisionEdit({open: true})}>Add new
                    division</Button>
                <div style={{flex: 1}}/>
                {
                    rolesProvider.isOrgAdmin() && <>
                        <Button size={"large"} onClick={() => deleteAll()}>Delete all</Button>
                    </>
                }
            </div>
            <div className={"page-content"}>
                {datasource !== undefined &&
                    <Table pagination={false} scroll={{y: '100%'}} dataSource={sortAndFilter(datasource, filter)}
                           rowKey={'id'}>
                        <Column<DivisionStatsDto> title={'Title'} dataIndex={'title'}
                                                  render={(text, record) =>
                                                      <a onClick={() => setDivisionEdit({
                                                          open: true,
                                                          id: record.id
                                                      })}>{text}</a>
                                                  }/>
                        <Column<DivisionStatsDto> title={'# of People'} dataIndex={'count'}
                                                  render={(text, record) =>
                                                      <a onClick={() => navigatePeople(record.id, record.title)}>{text}</a>}
                        />
                        <Column title={'Years'}
                                render={(_, record: DivisionDto) => ((record.startYear || record.endYear) ? (record.startYear ?? "") + " - " + (record.endYear ?? "") : "")}
                        />
                        <Column title={'Color'}
                                dataIndex={'color'}
                                render={x => !!x && <div className={"color-cell"}>
                                    <div style={{backgroundColor: "#" + x}}/>
                                </div>}
                        />
                    </Table>}
            </div>
        </div>
    </Navigation>;
}