import {useApi} from "./ApiProvider"
import React, {useEffect, useState} from "react";
import {PersonNameDtoWithId, PublicationListItemDto} from "../generated/api";
import {Button, Checkbox, Modal} from "antd";
import {formatPubJournal, formatPubSuffix} from "./PeoplePublications";
import {DeleteOutlined} from "@ant-design/icons";

interface RemovedPopupProps {
    open: boolean
    onClose: (shouldRedownload: boolean)=>void
    person?: PersonNameDtoWithId
}

export function RemovedPopup(props: RemovedPopupProps) {
    const api = useApi()

    const [removed, setRemoved] = useState<PublicationListItemDto[]>()
    const [removedFullList, setRemovedFullList] = useState(false);
    const [shouldRedownload, setShouldRedownload] = useState(false);

    useEffect(() => {
        if (props.person && props.open) {
            setRemoved(undefined)
            setShouldRedownload(false)
            api.listRemovedEntries(props.person.id, removedFullList).then(response => {
                setRemoved(response.data)
            })
        }
    }, [props.person, api, removedFullList, props.open])

    function removeRemoved(pmid: string) {
        if (props.person) {
            api.removeFromManual(props.person.id, [pmid]).then(() => {
                setShouldRedownload(true)
                setRemoved(oldState => {
                    return oldState?.filter(x => x.pmid !== pmid)
                })
            })
        }
    }

    return <Modal open={props.open}
                  title={<div style={{display: "flex"}}>
                      <span>Removed</span>
                      <div style={{flex: 1}}/>
                      <Checkbox checked={removedFullList} onChange={e => setRemovedFullList(e.target.checked)}>Full
                          List</Checkbox>
                      <div style={{width: 20}}/>
                  </div>}
                  onCancel={()=>props.onClose(shouldRedownload)}
                  footer={<Button type="primary" onClick={()=>props.onClose(shouldRedownload)}>
                      Close
                  </Button>}>
        <ol style={{paddingLeft: 29}}>
            {removed?.map(x=><li key={x.pmid} style={{paddingLeft: 6, paddingBottom: 6}}>
                <div>{x.title} {formatPubSuffix(x)}
                    <Button icon={<DeleteOutlined/>}
                            onClick={() => {
                                removeRemoved(x.pmid)
                            }}
                            danger={true}
                            type={"text"}
                            size={"small"}
                            style={{marginLeft: 5}}
                    />
                </div>
                <div style={{color: '#aaa'}}>{x.authorsList.map((z, i) => {
                    return <>
                        {i > 0 && <>, </>}
                        <span title={z.affiliations?.join("\n\n")}>
                                {z.lastName + " " + z.initials}
                            </span>
                    </>
                })}</div>
                {formatPubJournal(x)}
            </li>)}
        </ol>
    </Modal>
}