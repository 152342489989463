import {useApi} from "./ApiProvider";
import React, {useEffect, useState} from "react";
import {DivisionNameDto, FoundPersonDtoWithId} from "../generated/api";
import {Button, Table} from "antd";
import Column from "antd/es/table/Column";
import {PersonEdit} from "./PersonEdit";

export function NewPeople() {
    const api = useApi()
    const [data, setData] = useState<FoundPersonDtoWithId[]>()
    const [loading, setLoading] = useState(false)
    const [divisionNames, setDivisionNames] = useState<DivisionNameDto[]>();
    const [personEdit, setPersonEdit] = useState<{isOpen: boolean, found?: FoundPersonDtoWithId}>({isOpen: false})
    useEffect(() => {
        setLoading(true)
        api.foundPersons().then(response=>{
            setLoading(false)
            response.data.sort((a, b)=>a.lastName.localeCompare(b.lastName))
            setData(response.data)
        })
    }, []);

    useEffect(()=>{
        api.listDivisionNames().then(response => {
            response.data.sort((a, b) => a.title.localeCompare(b.title, undefined, {sensitivity: 'base'}))
            setDivisionNames(response.data)
        })
    })

    function formatName(x: FoundPersonDtoWithId) {
        if (x.foreName) {
            return x.lastName + ", " + x.foreName
        } else {
            return x.lastName + ", " + x.initials
        }
    }

    function ignorePerson(id: number) {
        api.ignoreFound({id: id}).then(()=>{
            removeFromList(id)
        })
    }

    function removeFromList(id: number) {
        setData(old=>{
            return old?.filter(x=>x.id!==id)
        })
    }

    return <>
        <PersonEdit open={personEdit.isOpen}
                    foundPerson={personEdit.found}
                    divisions={divisionNames}
                    personId={undefined}
                    onClose={(updated)=>{
                        if (updated!==undefined && personEdit.found?.id) {
                            removeFromList(personEdit.found.id)
                        }
                        setPersonEdit({isOpen: false})
                    }}
        />
        <Table scroll={{y: 'calc(100vh - 240px)'}} pagination={false} dataSource={data}
           locale={{emptyText: loading?"Loading":"No data collected yet"}}
               rowKey={"id"}
           loading={loading}
        >
            <Column<FoundPersonDtoWithId> title={"Person Name"}
                                    render={(_, rec) => <>{<a href={"#"} onClick={e=>{
                                        setPersonEdit({isOpen: true, found: rec})
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}>{formatName(rec)}</a>} <div style={{display: 'inline-block', marginLeft: 20}}> <Button size={"small"} onClick={()=>ignorePerson(rec.id)}>Ignore</Button></div></>}/>
            <Column<FoundPersonDtoWithId> title={"Publications"}
                               render={(_, rec) => <>{rec.publications.split(",").map((x, i)=>{
                                   return <>{i>0 && <>, </>}<a href={"https://pubmed.ncbi.nlm.nih.gov/" + x + "/"} target='_blank' rel='noreferrer'>{x}</a></>
                               })}</>} />
        </Table>
    </>
}