import {Button, Table, TableProps} from "antd";
import {AnyObject} from "antd/es/_util/type";
import {useRef} from "react";

export function TableWithCopy(props: TableProps<AnyObject>) {
    const divRef = useRef<HTMLDivElement | null>(null)

    function exportData() {
        const columns: string[] = []
        divRef.current?.querySelectorAll(".ant-table-column-title").forEach(x => {
            columns.push(x.textContent ?? "")
        })
        const rows: string[][] = []
        divRef.current?.querySelectorAll(".ant-table-row").forEach(x => {
            const row: string[] = []
            x.querySelectorAll(".ant-table-cell").forEach(z => {
                row.push(z.textContent ?? "")
            })
            rows.push(row)
        })

        function wrap(z: string) {
            if (z.includes(",")) return "\"" + z + "\"";
            return z;
        }

        const csv = columns.join(",") + "\n" + rows.map(x => x.map(z => wrap(z)).join(",")).join("\n")
        downloadBlob(csv, 'table.csv', 'text/csv;charset=utf-8;')
    }

    function downloadBlob(content: string, filename: string, contentType: string) {
        // Create a blob
        const blob = new Blob([content], {type: contentType});
        const url = URL.createObjectURL(blob);

        // Create a link to download it
        const pom = document.createElement('a');
        pom.href = url;
        pom.setAttribute('download', filename);
        pom.click();
    }

    return <div ref={divRef} className='table-export-wrap'>
        <Button type={'primary'} className={'export-data'} onClick={() => exportData()}>Export Data</Button>
        <Table {...props} />
    </div>
}