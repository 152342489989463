import {Button, Modal} from "antd";
import Dragger from "antd/es/upload/Dragger";
import {InboxOutlined} from '@ant-design/icons';
import {useApi} from "./ApiProvider"
import {RcFile} from "antd/es/upload/interface";
import {useState} from "react";

interface UploadDataPopupProps {
    onClose: () => void
    onLoaded: () => void
    isOpen: boolean
}

export function UploadDataPopup(props: UploadDataPopupProps) {
    const api = useApi();

    const [error, setError] = useState("")

    async function onUpload(file: RcFile) {
        const response = await api.uploadCSV(file)
        if (response.data) {
            setError(response.data)
        } else {
            props.onLoaded();
        }
        return false
    }

    function onOpen(open: boolean) {
        if (!open) {
            setError("")
        }
    }

    return <Modal open={props.isOpen}
                  afterOpenChange={onOpen}
                  onCancel={props.onClose}
                  onOk={props.onLoaded}
                  title={"Upload CSV"}
                  footer={[<Button key='close' onClick={props.onClose}>
                      Cancel
                  </Button>]}
    >
        <Dragger beforeUpload={onUpload} showUploadList={false}>
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
        </Dragger>
        <div className={"error"}>{error}</div>
    </Modal>
}