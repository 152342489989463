import React, {ReactNode, useContext} from "react";
import Keycloak from "keycloak-js";
import axios, {AxiosError} from "axios";
import {message} from "antd";
import {SettingsHolderContext} from "./SettingsHolder";
import {Configuration, MainApi} from "../generated/api";

export const ApiContext = React.createContext<MainApi | null>(null)

function getAxiosInstance(keycloak: Keycloak, inst?: string) {
    const axiosInstance = axios.create()

    axiosInstance.interceptors.request.use(
        (config) => {
            config.headers.setAuthorization(`Bearer ${keycloak.token}`)
            if (inst) {
                config.headers['X-Inst'] = inst
            }
            return config;
        },
        (error: AxiosError) => {
            return Promise.reject(error)
        });

    axiosInstance.interceptors.response.use(
        res => res,
        error => {
            if (!error?.config?._noMessage) {
                message.error("Error connecting to server, please try again").then()
            }
            return Promise.reject(error)
        }
    )
    return axiosInstance;
}

export function getApi(keycloak: Keycloak, inst?: string) {
    const axiosInstance = getAxiosInstance(keycloak, inst);
    const configuration = new Configuration({})
    return new MainApi(configuration, "", axiosInstance)
}

export function ApiProvider(props: { keycloak: Keycloak, children: ReactNode }) {
    const instHolder = useContext(SettingsHolderContext)
    return <ApiContext.Provider value={getApi(props.keycloak, instHolder?.inst)}>
        {props.children}
    </ApiContext.Provider>;
}

export function useApi() {
    const context = useContext(ApiContext)
    if (context === null) throw new Error("Api is not initialized")
    return context
}