import {Badge, Button, Modal, Spin} from "antd";
import React, {useEffect, useState} from "react";
import {useApi} from "./ApiProvider"
import {PersonNameDtoWithId, PublicationDto} from "../generated/api";
import {formatName} from "../utils/utils";
import {Bar, BarChart, LabelList, XAxis} from "recharts";
import {checkPosition, FilterAndSort} from "./FilterAndSort";
import {Props} from "recharts/types/component/Label";
import {RemovedPopup} from "./RemovedPopup";
import {useRolesProvider} from "../utils/keycloakUtils";
import {stopPropagationUtil} from "../utils/clicks";
import {AddMissingMultiplePopup} from "./AddMissingMultiplePopup";
import {PublicationsList} from "./PublicationsList";
import {ExportButton} from "./ExportButton";

interface PublicationsPopupProps {
    person?: PersonNameDtoWithId
    onClose: (shouldRefresh: boolean)=>void
    startYear?: number
    endYear?: number
    globalStartYear: number
    position?: string
}

function ChartLabel(p: Props) {
    return <text x={Number(p.x ?? 0) + Number(p.width ?? 0) / 2} y={Number(p.y ?? 0) - 5}
                 textAnchor="middle">{p.value ? p.value : ""}</text>
}

export function PublicationsPopup(props: PublicationsPopupProps) {
    const api = useApi()

    const [data, setData] = useState<PublicationDto[]>()
    const [startYear, setStartYear] = useState<number>()
    const [endYear, setEndYear] = useState<number>()
    const [position, setPosition] = useState<string>('all')
    const [sortPublications, setSortPublications] = useState("pmid")
    const rolesProvider = useRolesProvider();
    const [removedPopupOpen, setRemovedPopupOpen] = useState(false)
    const [addMissingPopupOpen, setAddMissingPopupOpen] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [minMax, setMinMax] = useState<number[]>()
    const [removedCount, setRemovedCount] = useState<number>()
    const [shouldRefresh, setShouldRefresh] = useState(false)

    function loadPagedData(personId: number, page: number) {
        setLoading(true)
        if (page===0) {
            setData([])
        }
        api.listPublications(personId, page, 200).then(response => {
            if (response.data.length) {
                setData(oldValue=>{
                    if (oldValue===undefined) return response.data
                    return [...oldValue, ...response.data]
                })
                if (response.data.length<200) {
                    setLoading(false)
                } else {
                    loadPagedData(personId, page+1)
                }
            } else {
                setLoading(false)
            }
        })
    }

    function downloadData() {
        if (props.person) {
            const personId = props.person.id;
            api.getMinMaxYears(personId).then(response=> {
                setMinMax(response.data)
                loadPagedData(personId, 0)
            })
            api.getRemovedCount(personId, false).then(response=>{
                setRemovedCount(response.data)
            })
        }
    }

    useEffect(()=>{
        if (props.person) {
            setData(undefined)
            setStartYear(props.startYear)
            setEndYear(props.endYear)
            downloadData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.person, props.startYear, props.endYear])

    function formatTitle() {
        return props.person?formatName(props.person):""
    }

    function renderBar(barProps: any) {
        barProps.className+=" year-chart-bar"
        if (startYear) {
            if (endYear) {
                if (Number(barProps.year)>=startYear && Number(barProps.year)<=endYear) {
                    barProps.className+=" selected"
                }
            } else {
                if (Number(barProps.year)===startYear) {
                    barProps.className+=" selected"
                }
            }
        }
        return Bar.renderRectangle(true, barProps);
    }

    function getYearsData(data: PublicationDto[]) {
        if (minMax) {
            const initial: { [key: number]: number } = {}
            for (let i = minMax[0]; i <= minMax[1]; i++) {
                initial[i] = 0;
            }
            return Object.entries(data?.reduce(function (rv, x) {
                if (checkPosition(position, x.position, x.authors)) {
                    rv[x.year] = (rv[x.year] ?? 0) + 1;
                }
                return rv;
            }, initial) ?? {}).map(x => ({year: x[0], count: x[1]}))
        }
    }

    function closeRemovedPopup(shouldRedownload: boolean) {
        setRemovedPopupOpen(false)
        if (shouldRedownload) {
            setShouldRefresh(true);
            downloadData()
        }
    }

    function closeAddMissingPopup(shouldRedownload: boolean) {
        setAddMissingPopupOpen(false)
        if (shouldRedownload) {
            setShouldRefresh(true);
            downloadData()
        }
    }

    return <Modal open={!!props.person}
                  centered={true}
                  afterOpenChange={open=>{setPosition(open?(props.position??'all'):'all')}}
                  onCancel={()=>props.onClose(shouldRefresh)}
                  title={<div style={{display: 'flex', alignItems: 'center', gap: 15}}>
                            {formatTitle()}
                            <FilterAndSort sortPublications={sortPublications} setSortPublications={setSortPublications} setPosition={setPosition} position={position}/>
                            <ExportButton person={props.person} filters={{position: position==='all'?undefined:[position], startYear: startYear, endYear: endYear}}/>
                            <div style={{flex: 1}}/>
                      {rolesProvider.isManager() &&
                          <Button size={"small"} type={'dashed'} onClick={()=>setAddMissingPopupOpen(true)} disabled={isLoading}>Import</Button>
                      }
                      {rolesProvider.isAdmin() && (removedCount??0)>0 &&
                          <Badge count={removedCount} size={"small"}>
                              <Button size={"small"} type={'dashed'} onClick={()=>setRemovedPopupOpen(true)}>Removed</Button>
                          </Badge>
                      }
                      <div style={{width: 20}}/>
                          </div>}
                  width={900}
                  footer={<Button type="primary" onClick={()=>props.onClose(shouldRefresh)}>
                              Close
                          </Button>}>
        <RemovedPopup open={removedPopupOpen}
                      onClose={closeRemovedPopup}
                      person={props.person}
        />
        <AddMissingMultiplePopup open={addMissingPopupOpen}
                                 allPublications={data}
                                 onClose={closeAddMissingPopup}
                                 person={props.person}
        />
        <Spin spinning={isLoading}>
            <div style={{overflowY: "auto", height: 600}}>
                {data!==undefined && props.person &&
                    <>
                    <BarChart width={650} height={120} data={getYearsData(data)}
                              onClick={()=>{
                                  setStartYear(undefined)
                                  setEndYear(undefined)
                              }}
                              margin={{top: 20}}
                              style={{margin: "0 auto"}}>
                        <XAxis dataKey="year" tickLine={false} />
                        {/*<YAxis allowDecimals={false} />*/}
                        <Bar dataKey="count" shape={renderBar} onClick={(data,index,e)=>{
                            stopPropagationUtil(e)
                            setStartYear(Number(data.year))
                            setEndYear(undefined)
                        }}>
                            <LabelList dataKey="count" position="top" content={ChartLabel} />
                        </Bar>
                    </BarChart>
                    <PublicationsList data={data}
                                      startYear={startYear}
                                      endYear={endYear}
                                      position={position}
                                      setData={handler=>{
                                          setData(handler)
                                          setShouldRefresh(true)
                                      }}
                                      setRemovedCount={setRemovedCount}
                                      personId={props.person.id}
                                      sortPublications={sortPublications}
                    />
                </>}
            </div>
        </Spin>
    </Modal>
}