import React, {useEffect, useState} from 'react';
import {DiffDto, PublicationListItemDto} from "../generated/api";
import {Button, Modal, ModalProps, Spin} from "antd";
import {formatPubJournal, formatPubSuffix} from "./PeoplePublications";
import {DeleteOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import {useApi} from "./ApiProvider"

interface PubDiffProps extends ModalProps {
    getData?: () => Promise<DiffDto>
    personId?: number
}

export function PubDiff(props: PubDiffProps) {
    const api = useApi()
    const [data, setData] = useState<DiffDto>();
    const [manualEntries, setManualEntries] = useState<{[pmid: string]: string}>({});

    useEffect(() => {
        if (props.personId) {
            setData(undefined);
            api.listManualEntries(props.personId, "all", true).then(response => {
                setManualEntries(Object.fromEntries(response.data.map(x => [x.pmid, x.type])))
            })
            props.getData?.().then(setData);
        }
    }, [props, api])

    function removeFromManual(pmid: string) {
        if (props.personId) {
            api.removeFromManual(props.personId, [pmid]).then(() => {
                setManualEntries(old => {
                    let newEntries = {...old}
                    if (newEntries[pmid]) delete newEntries[pmid];
                    return newEntries
                })
            })
        }
    }

    function addManual(pmid: string, type: string) {
        if (props.personId) {
            api.addManual(props.personId, type, [pmid]).then(() => {
                setManualEntries(old => {
                    let newEntries = {...old}
                    newEntries[pmid]=type
                    return newEntries
                })
            })
        }
    }

    function getButtonProps(pmid: string) {
        if (manualEntries[pmid]==="deleted") {
            return [{
                icon: <div className={"crossed-icon"}><DeleteOutlined /><div className={"inner"}></div></div>,
                onClick: ()=>removeFromManual(pmid),
                danger: true
            }]
        } else if (manualEntries[pmid]==="suspicious") {
            return [{
                icon: <div className={"crossed-icon"}><ExclamationCircleOutlined /><div className={"inner"}></div></div>,
                onClick: ()=>removeFromManual(pmid),
                danger: true,
                className: "btn-suspicious"
            }]
        } else {
            return [{
                icon: <DeleteOutlined/>,
                onClick: ()=>addManual(pmid, "deleted"),
                danger: true
            }, {
                icon: <ExclamationCircleOutlined />,
                onClick: ()=>addManual(pmid, "suspicious"),
                className: "btn-suspicious",
                danger: true
            }]
        }
    }


    function getClassName(pmid: string) {
        if (!manualEntries[pmid]) return "";
        return "manual-edit-" + manualEntries[pmid]
    }

    function getScore(item: PublicationListItemDto) {
        if (item.score) {
            return <div style={{marginRight: 5}} title={item.scoreDebug} className={item.maybe?"maybe-tag":"score-tag"}>{item.score.toFixed(2)}</div>
        }
    }

    return <Modal
        {...props}
        width={900}>
        <Spin spinning={data === undefined}>
            {data && <div style={{overflowY: "auto", height: 600}}>
                <h3>Extra</h3>
                {!data.extra?.length && <span style={{color: "green"}}>Nothing</span>}
                <ol style={{paddingLeft: 29}}>
                    {data.extra?.map(x => <li key={x.pmid} style={{paddingLeft: 6, paddingBottom: 6}}>
                        <div className={getClassName(x.pmid)}>{getScore(x)}{x.title} {formatPubSuffix({pmid: x.pmid})}
                            {getButtonProps(x.pmid).map(buttonProps =>
                                <Button {...buttonProps}
                                        type={"text"}
                                        size={"small"}
                                        style={{marginLeft: 5}}
                                />)}
                        </div>
                        <div style={{color: '#aaa'}}>{x.authorsList.map((z, i) => {
                            return <React.Fragment key={z.lastName + z.initials}>
                                {i > 0 && <>, </>}
                                <span title={z.affiliations?.join("\n\n")}>
                                    {z.inList &&
                                        <span className={"highlight-author"}>{z.lastName + " " + z.initials}</span>}
                                    {!z.inList && z.lastName + " " + z.initials}
                                </span>
                            </React.Fragment>
                        })}</div>
                        {formatPubJournal(x)}
                    </li>)}
                </ol>
                <h3>Not Found</h3>
                {!data.notFound?.length && <span style={{color: "green"}}>Nothing</span>}
                <ol style={{paddingLeft: 29}}>
                    {data.notFound?.map(x => <li key={x.pmid} style={{paddingLeft: 6, paddingBottom: 6}}>
                        <div className={getClassName(x.pmid)}>{getScore(x)}{x.title} {formatPubSuffix({pmid: x.pmid})}
                            {getButtonProps(x.pmid).map(buttonProps =>
                                <Button {...buttonProps}
                                        type={"text"}
                                        size={"small"}
                                        style={{marginLeft: 5}}
                                />)}
                        </div>
                        <div style={{color: '#aaa'}}>{x.authorsList?.map((z, i) => {
                            return <React.Fragment key={z.lastName + z.initials}>
                                {i > 0 && <>, </>}
                                <span title={z.affiliations?.join("\n\n")}>
                                    {z.inList &&
                                        <span className={"highlight-author"}>{z.lastName + " " + z.initials}</span>}
                                    {!z.inList && z.lastName + " " + z.initials}
                                </span>
                            </React.Fragment>
                        })}</div>
                        {formatPubJournal(x)}
                    </li>)}
                </ol>
                {!!data.allEntries && <>
                    <h3>All Entries</h3>
                    <ol style={{paddingLeft: 29}}>
                        {data.allEntries.map(x => <li key={x.pmid} style={{paddingLeft: 6, paddingBottom: 6}}>
                            <div className={getClassName(x.pmid)}>{getScore(x)}{x.title} {formatPubSuffix({pmid: x.pmid})}
                                {getButtonProps(x.pmid).map(buttonProps =>
                                    <Button {...buttonProps}
                                            type={"text"}
                                            size={"small"}
                                            style={{marginLeft: 5}}
                                    />)}
                            </div>
                            <div style={{color: '#aaa'}}>{x.authorsList?.map((z, i) => {
                                return <React.Fragment key={z.lastName + z.initials}>
                                    {i > 0 && <>, </>}
                                    <span title={z.affiliations?.join("\n\n")}>
                                        {z.inList &&
                                            <span className={"highlight-author"}>{z.lastName + " " + z.initials}</span>}
                                        {!z.inList && z.lastName + " " + z.initials}
                                    </span>
                                </React.Fragment>
                            })}</div>
                            {formatPubJournal(x)}
                        </li>)}
                    </ol>
                </>}
            </div>}
        </Spin>
    </Modal>

}