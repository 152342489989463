import {FiltersDto, JournalStatsProjection} from "../generated/api";
import {useApi} from "./ApiProvider";
import {useEffect, useState} from "react";
import Column from "antd/es/table/Column";
import {numberSorter, textSorter} from "../utils/utils";
import {FiltersUpdateContext, FiltersUpdateContextInterface} from "./FiltersComponent";
import {TableWithCopy} from "./TableWithCopy";

function JournalWidget(props: { refreshKey: number, filters: FiltersDto}) {
    const api = useApi()

    const [data, setData] = useState<JournalStatsProjection[]>()

    useEffect(() => {
        api.distributionJournal(props.filters).then(response => {
            const data = [...response.data];
            data.sort((a, b) => {
                return b.count - a.count
            })
            setData(data)
            const dataForPieChart = data.slice(0, 12)
            dataForPieChart.push(
                data.slice(12).reduce((prev, curr) => ({
                    count: prev.count + curr.count,
                    journal: 'Other',
                    first: prev.first + curr.first,
                    second: prev.second + curr.second,
                    last: prev.last + curr.last
                }))
            )
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.refreshKey, api, props.filters])

    function getColumnText(context: FiltersUpdateContextInterface, record: JournalStatsProjection, value: string, pos: string) {
        if (!value) return undefined;
        return <a onClick={() => {
            context.addFilter?.(['journal: ' + record.journal, "position:"+pos])
        }}>{value}</a>
    }

    return <FiltersUpdateContext.Consumer>
        {context=><div style={{flex: 1, position: 'relative', display: 'flex'}}>
            <TableWithCopy dataSource={data} size={"small"} pagination={false} scroll={{y: '100%'}}
                   className={"division-publications-table"}>
                <Column title={"Journal"} sorter={textSorter('journal')} width={"30%"}
                        render={record => <a onClick={() => {
                            context.addFilter?.(['journal: ' + record.journal])
                        }}>{record.journal}</a>}
                />
                <Column title={"Total"} dataIndex={'count'} sorter={numberSorter('count')}/>
                <Column title={"1st"} dataIndex={'first'} sorter={numberSorter('first')} render={(value, record) => {
                    return getColumnText(context, record, value, '1st')
                }}/>
                <Column title={"2nd"} dataIndex={'second'} sorter={numberSorter('second')} render={(value, record) => {
                    return getColumnText(context, record, value, '2nd')
                }}/>
                <Column title={"Last"} dataIndex={'last'} sorter={numberSorter('last')} render={(value, record) => {
                    return getColumnText(context, record, value, 'last')
                }}/>
            </TableWithCopy>
        </div>}
    </FiltersUpdateContext.Consumer>
}

export function JournalsTable(props: { refreshKey: number, filters: FiltersDto}) {
    return <>
        <div style={{display: "flex", flexDirection: "column", flex: 1}}>
            <div className={"chart-title"}>
                Journals
            </div>
            <JournalWidget refreshKey={props.refreshKey} filters={props.filters} />
        </div>
    </>
}