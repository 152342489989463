import React, {createContext, useContext, useMemo, useState} from "react";
import {Navigation} from "../Navigation";
import {BreadcrumbItemType} from "antd/es/breadcrumb/Breadcrumb";
import {useNavigate} from "react-router-dom";
import {SettingsHolderContext} from "../SettingsHolder";
import {ProjectFiltersDto} from "../../generated/api";
import {ProjectsDefaultLayout} from "./ProjectsDefaultLayout";
import {ProjectFiltersComponent, ProjectFiltersUpdateContext} from "./ProjectFiltersComponent";
import {ProjectsBudgetLayout} from "./ProjectsBudgetLayout";

export function formatCost(param1: number | string | undefined) {
    if (!param1) return "";
    const param = param1.toString()
    const num = Number(param)
    if (num<1000) return "$ " + param
    if (num<1000000) return "$ " + param.substring(0, param.length-3) + " " + param.substring(param.length-3, param.length)
    return "$ " + param.substring(0, param.length-6) + " "
                                 + param.substring(param.length-6, param.length-3) + " "
                                 + param.substring(param.length-3, param.length)
}

export function formatCostMin(param1: number | string | undefined) {
    if (!param1) return "";
    const param = param1.toString()
    const num = Number(param)
    if (num<1000) return param
    if (num<1000000) return param.substring(0, param.length-3) + "K"
    return param.substring(0, param.length-6) + "M"
}

export const ProjectsContext = createContext<{
    refresh?: ()=>void
}>({})

export function Projects() {
    const [refreshKey, setRefreshKey] = useState(0)
    const [filters, setFilters] = useState<string[]>([])
    const [filtersObj, setFiltersObj] = useState<ProjectFiltersDto>({})
    const [layout, setLayout] = useState('default')
    const navigate = useNavigate();

    const settingsHolder = useContext(SettingsHolderContext)

    if (settingsHolder?.inst==="NONEXISTENT") {
        navigate("/institutions")
    }

    const breadcrumbs: BreadcrumbItemType[] = [
        {title: 'NIH Projects'}
    ]

    const refreshMemo = useMemo(()=>{
        return {refresh: () => setRefreshKey(old=>old+1)}
    }, [setRefreshKey])

    function renderLayout(layout: string, filters: ProjectFiltersDto) {
        switch (layout) {
            case 'budgets':
                return <ProjectsBudgetLayout filters={filters} refreshKey={refreshKey}/>
            default:
                return <ProjectsDefaultLayout filters={filters} refreshKey={refreshKey}/>
        }
    }

    function constructFilters(val: string[]) {
        let ret: ProjectFiltersDto = {}
        val.forEach(x=>{
            if (x.includes(':')) {
                const value = x.split(':', 2)[1].trim();
                if (x.startsWith('year:')) {
                    const academic = value.split(",")
                    const yearArray = academic[0].split('-')
                    ret.startYear = Number(yearArray[0])
                    if (yearArray.length > 1) ret.endYear = Number(yearArray[1])
                } else if (x.startsWith('title:')) {
                    if (ret.title===undefined) ret.title=[]
                    ret.title.push(value)
                } else if (x.startsWith('division:')) {
                    if (ret.division===undefined) ret.division=[]
                    ret.division.push(value)
                }
            }
        })
        return ret
    }

    function updateFilters(val: string[]) {
        const filtersObj1 = constructFilters(val)
        setFiltersObj(filtersObj1)
        document.dispatchEvent(new CustomEvent('projectFiltersUpdated', {detail: filtersObj1}))
    }

    function addFilter(vals: string[]) {
        setFilters(old => {
            let clone = [...old]
            vals.forEach(val=> {
                const param = val.split(':', 2)[0].trim()
                let found = false
                for (let i = clone.length - 1; i >= 0; i--) {
                    if (clone[i].split(':')[0].trim() === param) {
                        if (found) {
                            clone.splice(i, 1)
                        } else {
                            found = true
                            clone[i] = val
                        }
                    }
                }
                if (!found) {
                    clone.push(val)
                }
                updateFilters(clone)
            })
            return clone
        })
    }

    function removeFilter(val: string) {
        setFilters(old=>{
            const ret = old.filter(x=>!x.startsWith(val))
            updateFilters(ret)
            return ret
        })

    }

    function setFiltersInt(val: string[]) {
        setFilters(val)
        updateFilters(val)
    }

    const filtersUpdate = useMemo(()=>({addFilter, removeFilter}), [])

    return <Navigation breadcrumbs={breadcrumbs} title={"Publications"}>
        <ProjectFiltersComponent filters={filters} setFilters={setFiltersInt} layout={layout} setLayout={setLayout}/>
        <ProjectFiltersUpdateContext.Provider value={filtersUpdate}>
            <ProjectsContext.Provider value={refreshMemo}>
                <React.Fragment key={layout}>{renderLayout(layout, filtersObj)}</React.Fragment>
            </ProjectsContext.Provider>
        </ProjectFiltersUpdateContext.Provider>
    </Navigation>
}