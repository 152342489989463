import {PersonYearsStatsDto, ProjectDto} from "../../generated/api";
import {Button, Flex, Modal, Popconfirm, Space, Switch, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {useApi} from "../ApiProvider";
import {ProjectPopup} from "./ProjectPopup";
import {CheckCircleOutlined, UndoOutlined, WarningOutlined} from "@ant-design/icons";
import {useRolesProvider} from "../../utils/keycloakUtils";

interface ProjectPIPopupProps {
    onClose: () => void
    person?: PersonYearsStatsDto
}

export function ProjectPIPopup(props: Readonly<ProjectPIPopupProps>) {
    const api=useApi();
    const [data, setData] = useState<ProjectDto[]>()
    const [showIgnored, setShowIgnored] = useState(false)
    const [selectedProjectId, setSelectedProjectId] = useState<{fullProjectNum: string, subprojectId: string, year: number}>();
    const [updateKey, setUpdateKey] = useState(0)
    const rolesProvider = useRolesProvider()

    useEffect(() => {
        if (props.person) {
            api.listProjectForPerson(props.person?.id, showIgnored).then(response=>setData(response.data))
        } else {
            setData(undefined)
            setShowIgnored(false)
        }
    }, [props.person]);

    function setStatus(project: ProjectDto, status: number) {
        const prj = data?.find(x=>x.fullProjectNum===project.fullProjectNum && x.year===project.year && x.subprojectId === project.subprojectId)
        if (prj) {prj.status=status}
        setUpdateKey(old=>old+1)
        if (props.person?.id) {
            api.projectStatus(props.person.id, project.fullProjectNum, project.year, project.subprojectId, status).then();
        }
    }

    function formatProject(project: ProjectDto, coreProjectName: string) {
        return <div style={{display: "inline-block"}} className={project.status===1?'removed-project':''}>
            <span className={'project-name'}><a href={'#'} onClick={()=>setSelectedProjectId(project)}>{project.fullProjectNum}</a> ({project.year}). {coreProjectName===project.title?'':(project.title+'.')}</span>
            {rolesProvider.isManager() && project.status !== 1 && <Popconfirm
                title={"Report incorrect listing"}
                description={"Mark this project as incorrectly listed, it will be removed from the list."}
                onConfirm={() => setStatus(project, 1)}
            >
                <Button icon={<WarningOutlined/>}
                        className={'report-incorrect'}
                        danger={true}
                        title={"Report incorrect listing"}
                        type={"text"}
                        size={"small"}
                        style={{marginLeft: 5}}
                /></Popconfirm>}
            {rolesProvider.isManager() && project.status === 1 && <Button icon={<UndoOutlined/>}
                                                                          className={'report-incorrect'}
                                                                          title={"Undo"}
                                                                          type={"text"}
                                                                          size={"small"}
                                                                          style={{marginLeft: 5, color: 'green'}}
                                                                          onClick={()=>setStatus(project, 0)}
            />}
        </div>
    }

    function generateCoreProjectName(x1: ProjectDto[]) {
        return x1[x1.length-1].title
    }

    return <Modal open={!!props.person}
                  centered={true}
                  onCancel={()=>props.onClose()}
                  title={<div style={{display: 'flex', alignItems: 'center', gap: 15}}>
                      <div>{props.person?.lastName}, {props.person?.firstName} {props.person?.middleName}</div>
                      <Space style={{marginBottom: 2}}><Switch checked={showIgnored} onChange={e=>setShowIgnored(e)} /><Typography.Text>Display ignored</Typography.Text></Space>
                  </div>}
                  width={900}
                  footer={<Button type="primary" onClick={()=>props.onClose()}>
                      Close
                  </Button>}
    >
        <ProjectPopup id={selectedProjectId} onClose={() => setSelectedProjectId(undefined)}/>
        <ol style={{height: '70vh', overflow: 'auto'}} className={'project-list'} key={updateKey+''+showIgnored}>
            {data!==undefined && Object.entries(data.reduce((res: {[key: string]: ProjectDto[]}, item)=>{
                res[item.coreProjectNum] = res[item.coreProjectNum]??[]
                res[item.coreProjectNum].push(item)
                return res
            }, {})).sort((a,b)=>a[0].localeCompare(b[0])).map(x=>{
                const coreProjectName = generateCoreProjectName(x[1]);
                return <li key={x[0]}>Core Project: {x[0]}. {coreProjectName}.
                    <ol>
                        {x[1].filter(y=>showIgnored || y.status===0).map(y=><li key={y.fullProjectNum+y.year}>{formatProject(y, coreProjectName)}</li>)}
                    </ol>
                </li>})}
        </ol>
    </Modal>
}