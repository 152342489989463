import {ChartYears} from "../ChartYears";
import {PeoplePublications} from "../PeoplePublications";
import {FiltersDto} from "../../generated/api";
import {JournalsTable} from "../JournalsTable";

interface LayoutProps {
    filters: FiltersDto
    refreshKey: number
}

export function JournalsLayout(props: LayoutProps) {
    return <>
        <div style={{display: "flex", flex: 1}} className={"analytics-page"}>
            <div style={{display: "flex", flexDirection: "column", minWidth: 400, width: "40%"}}>
                <JournalsTable filters={props.filters} refreshKey={props.refreshKey}/>
                <ChartYears filters={props.filters} refreshKey={props.refreshKey}/>
            </div>
            <PeoplePublications filters={props.filters} refreshKey={props.refreshKey}/>
        </div>
    </>
}