import {FiltersDto} from "../../generated/api";
import {YearsTable} from "../YearsTable";

interface LayoutProps {
    filters: FiltersDto
    refreshKey: number
}

export function YearsLayout(props: LayoutProps) {
    return <div style={{display: "flex", flex: 1}} className={"analytics-page"}>
            <YearsTable filters={props.filters} refreshKey={props.refreshKey}/>
        </div>
}