import {Navigation} from "./Navigation";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {SettingsHolderContext} from "./SettingsHolder";
import {useApi} from "./ApiProvider";
import {ReportDetailsDto} from "../generated/api";
import {formatPubJournal, formatPubSuffix} from "./PeoplePublications";
import {Button, Spin} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import {processDownload} from "../utils/utils";

function Section(props: { title: string, data: {
        [key: string]: ReportDetailsDto[]
    }}) {

    function formatPublication(pub: ReportDetailsDto[], index: number) {
        const p = pub[0].publication
        debugger;
        const positions = new Set(pub.filter(x=>x.publication!==undefined).flatMap(x=>x.publication?.positions))
        return <>{p && <div>
            <div>{index}. {p.title} {formatPubSuffix(p)}</div>
            <div style={{color: '#aaa'}}>{p.authorsList.map((z,i)=>{
                return <Fragment key={p.pmid}>
                    {i>0 && <>, </>}
                    <span title={z.affiliations?.join("\n\n")}>
                        {positions.has(i)
                            ?
                        <span className={"highlight-author"}>{z.lastName+" "+z.initials}</span>
                            :
                        <>{z.lastName+" "+z.initials}</>}
                    </span>
                </Fragment>
            })}</div>
            {formatPubJournal(p)}
        </div>}</>
    }

    return <>{Object.entries(props.data).length>0 && <>
            <h4 style={{marginTop: 0}}>{props.title}</h4>
            {Object.entries(props.data).map((x, i)=><div key={x[0]} style={{marginBottom: 10}}>
                {formatPublication(x[1], i+1)}
            </div>)}
        </>}</>
}

export function SingleReport() {
    const { period, date } = useParams();
    const navigate = useNavigate();
    const settingsHolder = useContext(SettingsHolderContext)
    const api=useApi()
    const [data, setData] = useState<ReportDetailsDto[]>()
    const [loading, setLoading] = useState(false)
    const [exportLoading, setExportLoading] = useState(false)

    if (settingsHolder?.inst==="NONEXISTENT") {
        navigate("/institutions")
    }

    function aggregate(data: ReportDetailsDto[] | undefined, filter: (x: ReportDetailsDto)=>boolean) {
        return data!==undefined?data.filter(x=>filter(x)).reduce((p: {
            [key: string]: ReportDetailsDto[]
        }, c)=>{
            if (c.publication) {
                p[c.publication.pmid ?? ""] = [...p[c.publication.pmid ?? ""] ?? [], c]
            }
            return p
        }, {}):{}
    }

    function newPubs() {
        return aggregate(data, x=>x.new)
    }

    function highCit() {
        return aggregate(data, x=>x.citationThreshold??false)
    }

    function highRCR() {
        return aggregate(data, x=>x.rcrThreshold??false)
    }

    useEffect(()=>{
        if (period && date) {
            setLoading(true)
            api.getReportDetails(period, date).then(response=>{
                setLoading(false)
                setData(response.data)
            })
        }
    }, [period, date, settingsHolder?.inst])

    function formatDate(d: Date) {
        return (d.getUTCMonth()+1)+"/"+d.getUTCDate()+"/"+d.getUTCFullYear()
    }

    function formatTitle() {
        const d = new Date(Date.parse(date!))
        const from = formatDate(d)
        if (period==='weekly') {
            const to = formatDate(new Date(Number(d)+6*24*3600*1000))
            return "Updates " + from + " - " + to
        } else {
            const toDate = new Date(Number(d));
            toDate.setMonth(toDate.getMonth()+1);
            return "Updates " + from + " - " + formatDate(toDate)
        }
    }

    function exportCall() {
        if (period && date) {
            setExportLoading(true)
            api.exportReportDetails(period, date, {responseType: "arraybuffer"})
                .finally(() => {
                    setExportLoading(false)
                })
                .then(processDownload(period + date + ".docx"))
        }
    }

    return <Navigation breadcrumbs={[{title: <a style={{height: 'auto'}} onClick={()=>navigate("/reports")}>All Reports</a>},
        {title: <>{formatTitle()} <Button icon={<DownloadOutlined/>} size={"small"} style={{marginLeft: 10, position: "relative", top: -2}}
                                          type={"default"}
                                          loading={exportLoading}
                                          onClick={exportCall}
            >Export</Button></>}]}>
        <div style={{display: 'flex', flex: 1, overflowY: 'auto'}}>
            {loading && <Spin spinning={loading} style={{flex: 1}} />}
            {!loading && <div>
                <Section title={"New Publications"} data={newPubs()} />
                <Section title={"Become Highly Cited (number of citations>10)"} data={highCit()} />
                <Section title={"Gaining RCR (RCR>2.5)"} data={highRCR()} />
            </div>}
        </div>
    </Navigation>
}