import {AxiosResponse} from "axios";
import {RankOrder} from "../components/People";

export function textSorter(field: string) {
    return (a: any, b: any) => a[field].localeCompare(b[field], undefined, {sensitivity: 'base'})
}

export function numberSorter(field: string) {
    return (a: any, b: any) => (a[field]??0) - (b[field]??0)
}

export function rankSorter(field: string) {
    const mapOrders = Object.fromEntries(RankOrder.map((x, i)=>[x, i]))
    return (a: any, b: any) => (mapOrders[a[field]]??0) - (mapOrders[b[field]]??0)
}

export function formatName(record: {lastName: string, firstName: string, middleName?: string}) {
    return record.lastName+", "+record.firstName + " " + (record.middleName??"")
}

export const range = function* (to = 0, step = 1, from = 0) {
    let i = 0,
        length = Math.floor((to - from) / step) + 1;
    while (i < length) yield from + i++ * step;
}

export const checkYear = async (value: any) => {
    if (value===undefined || value==="" || value===null) return;
    if (isNaN(Number(value))) throw Error("invalid value")
    if (Number(value)<1900) throw Error("invalid value")
    if (Number(value)>new Date().getFullYear()) throw Error("invalid value")
}

export const checkDay = async (value: any, mon: any) => {
    if (value===undefined || value==="" || value===null) return;
    if (isNaN(Number(value))) throw Error("invalid value")
    if (Number(value)<1) throw Error("invalid value")
    if (Number(value)>31) throw Error("invalid value")
    if (mon && !isNaN(Number(mon))) {
        if (Number(mon)===2)  if (Number(value)>28) throw Error("invalid value")
        if (Number(mon)===4 || Number(mon)===6 || Number(mon)===9 || Number(mon)===11) if (Number(value)>30) throw Error("invalid value")
    }
}

export const processDownload = (fileName: string) => {
    return (response: AxiosResponse)=>{
        // @ts-ignore
        const contentType: string = response.headers.getContentType()
        const data = new Blob([response.data], {type: contentType});
        const csvURL = window.URL.createObjectURL(data);
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', fileName);
        tempLink.click();
        window.URL.revokeObjectURL(csvURL);
    }
}

const ext: {[key: string]: string} = {
    doc: ".docx",
    xls: ".xlsx",
    pmid: ".txt"
}

export function getExtension(key: string) {
    return ext[key]
}