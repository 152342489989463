import {FiltersDto, PersonNameDtoWithId, PersonYearsStatsDto, SettingsDto} from "../generated/api";
import Column from "antd/es/table/Column";
import {formatName, numberSorter, range, textSorter} from "../utils/utils";
import {Input} from "antd";
import {SettingsContext} from "./Navigation";
import {stopPropagationUtil} from "../utils/clicks";
import {CloseOutlined, SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {useEffect, useState} from "react";
import {AnalyticsContext} from "./Analytics";
import {PublicationsPopup} from "./PublicationsPopup";
import {useApi} from "./ApiProvider";
import {TableWithCopy} from "./TableWithCopy";

export function YearsTable(props: { refreshKey: number, filters: FiltersDto}) {
    const [searchText, setSearchText] = useState('');
    const [selectedPerson, setSelectedPerson] = useState<{
        person: PersonNameDtoWithId,
        position?: string,
        year?: number
    }>()
    const [data, setData] = useState<PersonYearsStatsDto[]>();
    const api=useApi();

    useEffect(()=>{
        api.listPersonsByYearAndDivision({
            ...props.filters,
            startYear: undefined,
            endYear: undefined
        }).then(response=>{
            setData(response.data)
        })
    }, [props.filters, props.refreshKey, api])


    function getYears(settings?: SettingsDto) {
        return [...range(new Date().getFullYear(), 1, settings?.startYear??2000)]
    }

    function formatYear(y: number) {
        return "'" + ("0"+(y % 100)).slice(-2);
    }

    function getNameColumn(width: string) {
        return <Column<PersonYearsStatsDto>
            width={width}
            title={
                <div className={"col-header"} onClick={e => stopPropagationUtil(e)}><span>Name</span>
                    <Input value={searchText} onChange={e => setSearchText(e.target.value)}
                           prefix={<SearchOutlined/>}
                           suffix={<a onClick={() => {
                               setSearchText("");
                           }}><CloseOutlined/></a>}
                    />
                </div>}
            sorter={textSorter('lastName')}
            render={(_, record) =>
                <a onClick={() => {
                    setSelectedPerson({person: record})
                }}>{
                    <Highlighter
                        highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={formatName(record)}
                    />
                }</a>
            }
        />;
    }

    function yearSorter(y: number) {
        return (a: PersonYearsStatsDto, b: PersonYearsStatsDto)=>(a.years[y.toString()]??0)-(b.years[y.toString()]??0);
    }

    function filteredData(): PersonYearsStatsDto[] | undefined {
        return data?.filter(x=>formatName(x).toLowerCase().includes(searchText.toLowerCase()));
    }

    return <SettingsContext.Consumer>
        {settings=><div style={{flex:1, display: 'flex', flexDirection: 'column'}}>
            {settings && <AnalyticsContext.Consumer children={
                analyticsContext => <PublicationsPopup startYear={props.filters.startYear ?? selectedPerson?.year}
                                                       endYear={props.filters.endYear}
                                                       globalStartYear={settings.startYear}
                                                       person={selectedPerson?.person}
                                                       position={selectedPerson?.position}
                                                       onClose={shouldRefresh => {
                                                           setSelectedPerson(undefined)
                                                           if (shouldRefresh) analyticsContext.refresh?.()
                                                       }}/>
            } />}
            <TableWithCopy size={"small"} scroll={{y: '100%'}} className={"people-publications-table"} dataSource={filteredData()}
                   rowKey={'id'}
                   style={{flex: 1}} pagination={false}>
                {getNameColumn("20%")}

                <Column title={"Total"}
                        dataIndex={"count"}
                        defaultSortOrder={'descend'}
                        sorter={numberSorter('count')}
                        onHeaderCell={()=>({align: "center", className: "yc"})}
                        onCell={()=>({align: "center", className: "yc"})}
                />
                {getYears(settings).map(y=><Column<PersonYearsStatsDto> key={y} title={formatYear(y)} sorter={yearSorter(y)}
                                                                          render={(val, record)=><a onClick={() => setSelectedPerson({person: record, year: y})}>{record.years[y]}</a>}
                                                                          onHeaderCell={()=>({align: "center", className: "yc"})}
                                                                          onCell={()=>({align: "center", className: "yc"})}
                />)}
            </TableWithCopy>
        </div>}
    </SettingsContext.Consumer>

}