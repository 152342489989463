import * as React from "react";
import {useEffect, useState} from "react";
import {Button, InputNumber, Popover, Slider} from "antd";
import {CloseOutlined} from "@ant-design/icons";

interface CitationsTagProps {
    closeSelectDropdown: ()=>void
    onRemove: (event?: React.MouseEvent<HTMLElement, MouseEvent>)=>void
    value: string
    setValue: (value: string)=>void
    citationsOpen: boolean
    setCitationsOpen: (open: boolean)=>void
}

export function CitationsTag(props: CitationsTagProps) {
    const [citations, setCitations] = useState<string>("10")

    useEffect(()=>{
        if (props.value.startsWith("citations:")) {
            const val = props.value.split(':', 2)[1].trim()
            setCitations(val)
        }
    }, [props.value])

    function getButtonTitle() {
        if (!props.value || props.value==='citations') return "Set minimum Citations"
        return "Citations>=" + props.value.split(':', 2)[1].trim()
    }

    return <div className={'tag'} onMouseDown={e=>e.stopPropagation()}>
        <Popover content={
            <div style={{display: "flex", gap: 10, flexDirection: "column", alignItems: "flex-end"}}>
                <div style={{display: "flex", gap: 16}}>
                    <Slider
                        style={{flex: 1, width: 200}}
                        min={0}
                        max={50}
                        onChange={v=>setCitations(v.toString())}
                        value={Number(citations)}
                        step={1}
                    />
                    <InputNumber
                        min={0}
                        max={50}
                        step={1}
                        value={Number(citations)}
                        onChange={v=>v && setCitations(v.toString())}
                    />
                </div>
                <div>
                    <Button type={"primary"} onClick={()=>{
                        props.setValue(citations)
                        props.setCitationsOpen(false)
                    }}>OK</Button>
                </div>
            </div>}
                 style={{width: 400}}
                 arrow={false}
                 trigger={'click'}
                 placement={"bottomLeft"}
                 destroyTooltipOnHide={true}
                 align={{offset: [0, 6]}}
                 onOpenChange={(open)=>{
                     if (open) {
                         props.closeSelectDropdown()
                     }
                     props.setCitationsOpen(open)
                 }}
                 open={props.citationsOpen}
        >
            <Button size={"small"} type={'link'}>{getButtonTitle()}</Button>
        </Popover>
        <Button type={'text'} size={"small"} className={'close-tag'} onClick={props.onRemove}><CloseOutlined/></Button>
    </div>
}

