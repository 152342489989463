import {Navigation} from "./Navigation";
import {ResponsiveContainer, Tooltip, Treemap} from "recharts";
import {PureComponent, useEffect, useState} from "react";
import {Spin} from "antd";
import {useApi} from "./ApiProvider";
import {ClusterDtoChild} from "../generated/api";

class CustomRenderer extends PureComponent {
  render() {
    const { root, depth, x, y, width, height, index, payload, rank, name } = this.props as any;
    return (
        <g>
          <rect
              x={x}
              y={y}
              width={width}
              height={height}
              style={{
                fill: '#f00',
                stroke: '#fff',
                strokeWidth: 2 / (depth + 1e-10),
                strokeOpacity: 1 / (depth + 1e-10),
              }}
          />
          {depth === 1 ? (
              <text x={x + width / 2} y={y + height / 2 + 7} textAnchor="middle" fill="#fff" fontSize={14}>
                {name}
              </text>
          ) : null}
          {depth === 1 ? (
              <text x={x + 4} y={y + 18} fill="#fff" fontSize={16} fillOpacity={0.9}>
                {index + 1}
              </text>
          ) : null}
        </g>
    );
  }
}

export function Clusters() {
  const api = useApi()

  const [data, setData] = useState<ClusterDtoChild[]>()
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    setData(undefined)
    setLoading(true)
    api.getClusters().then(response=>{
      setData(response.data)
      setLoading(false)
    })
  }, [api])

  return <Navigation breadcrumbs={[{title: 'Clusters'}]} title={"Clusters"}>
    <div style={{flex: 1, position: 'relative', display: 'flex', marginBottom: 16}} >
      <Spin spinning={loading}>
        {data && <ResponsiveContainer width="100%" height="100%" className={'absolute-pos'}>
            <Treemap
                width={400}
                height={200}
                data={data}
                dataKey="size"
                stroke="#fff"
                fill="#8884d8"
                isAnimationActive={false}
            >
              <Tooltip content={props=>{
                return <div style={{whiteSpace: 'pre', background: 'white', padding: 5, border: 'solid 1px #777'}}>{props.payload?.map(x=>{
                  return x.payload.root.name + "\n\n" + x.payload.name
                }).join(' ')}</div>
              }}/>
            </Treemap>
        </ResponsiveContainer>}
      </Spin>
    </div>
  </Navigation>
}