/* eslint-disable jsx-a11y/anchor-is-valid */
import {Bar, BarChart, LabelList, ResponsiveContainer, XAxis, YAxis} from "recharts";
import {MouseEvent, useEffect, useState} from "react";
import '../Charts.scss'
import {Segmented, Spin} from "antd";
import {SegmentedValue} from "antd/es/segmented";
import Column from "antd/es/table/Column";
import {numberSorter} from "../../utils/utils";
import {ProjectFiltersDto, StatsProjection, StatsProjectionWithPositions} from "../../generated/api";
import {stopPropagationUtil} from "../../utils/clicks";
import {useApi} from "../ApiProvider";
import {TableWithCopy} from "../TableWithCopy";
import {ProjectFiltersUpdateContext} from "./ProjectFiltersComponent";

interface ProjectsChartYearsProps {
    filters: ProjectFiltersDto
    refreshKey: number
}

function Label(p: any) {
    return <text x={Number(p.x??0)+Number(p.width??0)/2} y={Number(p.y??0)-5} textAnchor="middle" fontSize={10}>{p.value?p.value:""}</text>
}

export function ProjectsChartYears(props: ProjectsChartYearsProps) {
    const [chartOrTable, setChartOrTable] = useState<SegmentedValue>('Chart')
    const [refreshBarChart, setRefreshBarChart] = useState(0)
    const [animationActive, setAnimationActive] = useState(true)
    const api=useApi()
    const [data, setData] = useState<StatsProjection[]>()
    const [filters, setFilters] = useState(props.filters)

    function isSelectedYear(year: number) {
        if (filters.startYear) {
            if (filters.endYear) {
                return (year>=filters.startYear && year<=filters.endYear)
            }
            return year===filters.startYear
        }
        return false
    }

    useEffect(()=>{
        const listener = (e: Event)=>{
            setFilters((e as CustomEvent).detail)
        }
        document.addEventListener('projectFiltersUpdated', listener)
        return ()=>{
            document.removeEventListener('projectFiltersUpdated', listener)
        }
    }, [])

    useEffect(()=>{
        api.projectsDistributionYear(filters).then(response=>{
            setData(response.data)
        });
    },[props.refreshKey, /*filters.startYear, filters.endYear,*/ filters.title, filters.division])

    function renderBar(barProps: any) {
        if (!barProps.className) barProps.className=""
        barProps.className+=" year-chart-bar "

        if (isSelectedYear(barProps.id)) {
            barProps.className+=" selected"
        }
        return Bar.renderRectangle(true, barProps);
    }

    function reorder(data: StatsProjection[] | undefined) {
        if (data) {
            let newData = [...data];
            newData.sort((a,b)=>b.id-a.id);
            return newData
        }
        return undefined;
    }

    return <ProjectFiltersUpdateContext.Consumer>
        {context=><div style={{display: "flex", flexDirection: "column", flex: 1}}>
            {data && <>
                <div className={"chart-title"}>By Year <Segmented options={['Chart', 'Table']}
                                                                  onChange={value => setChartOrTable(value)}
                                                                  style={{marginLeft: 10}}/>
                </div>
                <div style={{flex: 1, display: "flex"}}>
                    {chartOrTable==='Chart' &&
                    <ResponsiveContainer width="100%" height="100%" className={"absolute-pos"} onResize={()=> {
                        setAnimationActive(false);
                        setTimeout(()=>{
                            setAnimationActive(true)
                            setRefreshBarChart(x => x + 1)
                        }, 1000);
                    }}>
                        <BarChart data={data} onClick={()=>context.removeFilter?.('year')} margin={{top: 15}} key={refreshBarChart}>
                            <XAxis dataKey="id" tickLine={true} fontSize={10} tickSize={3} />
                            <YAxis allowDecimals={false} />
                            <Bar dataKey="count" shape={renderBar} onClick={(data: StatsProjectionWithPositions, index: number, e: MouseEvent<SVGPathElement>)=>{
                                stopPropagationUtil(e)
                                context.addFilter?.(['year:' + data.id])
                            }} stackId="stack" isAnimationActive={animationActive}>
                                <LabelList dataKey="count" position="top" content={Label} />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>}
                    {chartOrTable==='Table' &&
                        <TableWithCopy dataSource={reorder(data)} size={"small"} pagination={false} scroll={{y: '100%'}} className={"division-publications-table"} rowKey={'id'} >
                            <Column title={"Year"} dataIndex={'id'} sorter={numberSorter('id')}/>
                            <Column title={"Total"} dataIndex={'count'} sorter={numberSorter('count')} render={(value, record)=>
                                <a onClick={()=>context.addFilter?.(['year:' + record.id])}>{value}</a>
                            }/>
                        </TableWithCopy>}
                </div>
            </>}
            {!data && <Spin/>}
        </div>}
    </ProjectFiltersUpdateContext.Consumer>
}