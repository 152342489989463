import {Button, Modal, Spin} from "antd";
import {useEffect, useState} from "react";
import {useApi} from "./ApiProvider"
import {PublicationWithDivisionsDto, SettingsDto} from "../generated/api";
import {Bar, BarChart, LabelList, XAxis} from "recharts";
import {SettingsContext} from "./Navigation";
import {formatPubJournal, formatPubSuffix} from "./PeoplePublications";
import {stopPropagationUtil} from "../utils/clicks";

interface MultiDivisionPopupProps {
    isOpen: boolean
    onClose: ()=>void
    startYear?: number
    endYear?: number
}

export function MultiDivisionPopup(props: MultiDivisionPopupProps) {
    const api = useApi()

    const [data, setData] = useState<PublicationWithDivisionsDto[]>()
    const [startYear, setStartYear] = useState<number>()
    const [endYear, setEndYear] = useState<number>()

    useEffect(()=>{
        setData(undefined)
        setStartYear(props.startYear)
        setEndYear(props.endYear)
        if (props.isOpen) {
            api.listPublicationsMultiDivision().then(response => {
                setData(response.data)
            })
        }
    }, [props.isOpen, props.startYear, props.endYear, api])

    function formatTitle() {
        return "Publications with multiple divisions"
    }

    function renderBar(barProps: any) {
        barProps.className+=" year-chart-bar"
        // eslint-disable-next-line eqeqeq
        if (startYear) {
            if (endYear) {
                if (barProps.year >= startYear && barProps.year <= endYear) {
                    barProps.className += " selected"
                }
            } else {
                if (barProps.year == startYear) {
                    barProps.className += " selected"
                }
            }
        }
        return Bar.renderRectangle(true, barProps);
    }

    function getYearsData(settings?: SettingsDto) {
        const initial: {[key: number]: number} = {}
        if (settings) {
            for (let i = settings?.startYear; i <= new Date().getFullYear(); i++) {
                initial[i] = 0;
            }
            return Object.entries(data?.reduce(function (rv, x) {
                rv[x.year] = (rv[x.year] ?? 0) + 1;
                return rv;
            }, initial) ?? {}).map(x => ({year: x[0], count: x[1]}))
        } else {
            return []
        }
    }

    function formatBarLabel(p: any) {
        return <text x={Number(p.x??0)+Number(p.width??0)/2} y={Number(p.y??0)-5} textAnchor="middle">
            {p.value??""}
        </text>
    }

    return <Modal open={props.isOpen}
                  onCancel={props.onClose}
                  title={formatTitle()}
                  width={900}
                  centered={true}
                  footer={<Button type="primary" onClick={props.onClose}>
                              Close
                          </Button>}>
        <div style={{overflowY: "auto", height: 600}}>
            {data!==undefined &&
                <>
                    <SettingsContext.Consumer>
                        {settings =>
                        <BarChart width={650} height={120} data={getYearsData(settings)}
                                  onClick={()=>setStartYear(undefined)}
                                  margin={{top: 15}}
                                  style={{margin: "0 auto"}}>
                            <XAxis dataKey="year" tickLine={false} />
                            {/*<YAxis allowDecimals={false} />*/}
                            <Bar dataKey="count" shape={renderBar} onClick={(data,index,e)=>{
                                stopPropagationUtil(e)
                                setStartYear(Number(data.year))
                                setEndYear(Number(data.year))
                            }}>
                                <LabelList dataKey="count" position="top" content={formatBarLabel} />
                            </Bar>


                        </BarChart>}
                    </SettingsContext.Consumer>

                    <ol style={{paddingLeft: 29}}>
                        {data.filter(x=>!startYear || x.year===startYear || (endYear && x.year>=startYear && x.year<=endYear)).map(x=><li key={x.pmid} style={{paddingLeft: 6, paddingBottom: 6}}>
                            <div>{x.title} {formatPubSuffix(x)}</div>
                            <div style={{color: '#aaa'}}>{x.authorsList.map((z,i)=>{
                                return <>
                                    {i>0 && <>, </>}
                                    <span title={z.affiliations?.join("\n\n")}>
                                        {z.lastName+" "+z.initials} {[...z.divisions].length>0 && <span className={"highlight-division"}>{[...z.divisions].map(x=>x).join(", ")}</span>}
                                    </span>
                                </>
                            })}</div>
                            {formatPubJournal(x)}
                        </li>)}
                    </ol>
                </>}
            {data===undefined &&
                <Spin/>
            }
        </div>
    </Modal>
}