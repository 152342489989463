import {Navigation} from "./Navigation";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {SettingsHolderContext} from "./SettingsHolder";
import {Table, Tabs} from "antd";
import Column from "antd/es/table/Column";
import {useApi} from "./ApiProvider";
import {ReportDto} from "../generated/api";
import {NewPeople} from "./NewPeople";

function Report(props: { period: string }) {
    const api = useApi()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<ReportDto[]>([])
    const navigate = useNavigate();

    useEffect(()=>{
        setLoading(true)
        api.getReports(props.period).then(response=>{
            setData(response.data)
            setLoading(false)
        })
    }, [api, props.period])

    function showReport(record: ReportDto) {
        navigate("/reports/"+props.period+"/"+record.from);
    }

    function pluralize(word: string, num: number) {
        if (num===1) return word;
        if (word.endsWith("s")) return word + "es";
        return word + "s";
    }

    return <Table scroll={{y: 'calc(100vh - 240px)'}} pagination={false} dataSource={data}
                   locale={{emptyText: loading?"Loading":"No data collected yet"}}
                   loading={loading}
                   rowKey={"timeframe"}
            >
                <Column<ReportDto> title={"Timeframe"} dataIndex={"timeframe"}
                                   key={"c1"}
                        render={(text, rec) => <><a>{text}</a> {rec.current && <span>(current)</span>}</>} onCell={record=>({
                            onClick: function() {
                                showReport(record)
                            }
                        })
                }/>
                <Column title={"Updates"} width={"80%"} key={"c2"} render={record=>{
                    let arr: string[] = [];
                    if (record.newPublications) {
                        arr.push(record.newPublications + " new " + pluralize("publication", record.newPublications))
                    }
                    if (record.highlyCited) {
                        arr.push(record.highlyCited + " " + pluralize("publication", record.highlyCited) + " reached the threshold of 10 citations")
                    }
                    if (record.highRCR) {
                        arr.push(record.highRCR + " " + pluralize("publication", record.highRCR) + " reached high RCR (RCR>2.5)")
                    }
                    if (!arr.length) arr=["No updates"]
                    return <>{arr.join(", ")}</>
                }}/>
            </Table>

}

export function Reports() {
    const navigate = useNavigate();
    const settingsHolder = useContext(SettingsHolderContext)

    if (settingsHolder?.inst==="NONEXISTENT") {
        navigate("/institutions")
    }

    return <Navigation breadcrumbs={[{title: 'All Reports'}]}>
        <Tabs defaultActiveKey="settings" items={[
            {
                key: 'weekly',
                label: 'Weekly Updates',
                children: <Report period={"weekly"} />
            }, {
                key: 'monthly',
                label: 'Monthly Updates',
                children: <Report period={"monthly"} />
            }, ...(settingsHolder?.settings?.foundPersonsFeature)?[{
                key: 'persons',
                label: 'New People',
                children: <NewPeople />
            }]:[]
        ]}/>
    </Navigation>;
}