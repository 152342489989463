import {Button, Form, Input, Modal, ModalProps, Popconfirm, Select} from "antd";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import React, {useEffect, useMemo, useState} from "react";
import {useApi} from "./ApiProvider"
import {DivisionNameDto, FoundPersonDtoWithId, PersonDto, PersonDtoWithId,} from "../generated/api";
import {useForm} from "antd/es/form/Form";
import {Ranks} from "./People";
import {checkDay, checkYear} from "../utils/utils";
import {useRolesProvider} from "../utils/keycloakUtils";
import TextArea from "antd/es/input/TextArea";

interface PersonEditProps extends ModalProps {
    personId?: number;
    onClose: (updated?: PersonDtoWithId | null)=>void;
    divisions?: DivisionNameDto[]
    foundPerson?: FoundPersonDtoWithId
}

export function PersonEdit(props: PersonEditProps) {
    const [form] = useForm()
    const [updating, setUpdating] = useState(false)
    const api = useApi()
    const rolesProvider = useRolesProvider();
    
    useEffect(()=> {
        if (props.open) {
            form.resetFields()
            if (props.personId) {
                api.getPerson(props.personId).then(response => {
                    form.setFieldsValue(response.data)
                })
            } else {
                if (props.foundPerson) {
                    const foreName = props.foundPerson.foreName?.split(' ')
                    form.setFieldValue('firstName', foreName?.[0])
                    form.setFieldValue('lastName', props.foundPerson.lastName)
                    form.setFieldValue('middleName', foreName?.[1])
                }
                form.setFieldValue('academicRank', 'none')
            }
            setUpdating(false)
        }
    }, [form, api, props.personId, props.open])

    function saveObject(values: PersonDto) {
        setUpdating(true);
        const promise = props.personId ? api.updatePerson(props.personId, values): api.createPerson(values)

        promise.then(response=>{
            setUpdating(false);
            props.onClose(response.data)
        })
    }

    function deleteObject() {
        if (props.personId) {
            api.deletePerson(props.personId).then(() => {
                props.onClose(null)
            })
        }
    }

    const months = useMemo(()=>[
                                        { value: null, label: "-"},
                                        { value: 1, label: "Jan"},
                                        { value: 2, label: "Feb"},
                                        { value: 3, label: "Mar"},
                                        { value: 4, label: "Apr"},
                                        { value: 5, label: "May"},
                                        { value: 6, label: "Jun"},
                                        { value: 7, label: "Jul"},
                                        { value: 8, label: "Aug"},
                                        { value: 9, label: "Sep"},
                                        { value: 10, label: "Oct"},
                                        { value: 11, label: "Nov"},
                                        { value: 12, label: "Dec"},
                                    ], [])

    return <Modal
        {...props}
        title={props.personId?"Update person":"New person"}
        onCancel={()=>props.onClose()}
        footer={<div style={{display: 'flex'}}>
            {!!props.personId &&
                <Popconfirm
                    title="Delete this person"
                    description="Are you sure to delete this person?"
                    onConfirm={deleteObject}
                    okText="Yes"
                    cancelText="No"
                >
                <Button key="delete" danger={true}>
                Delete
                </Button>
            </Popconfirm>}
            <div className={"spacer"} />
            <Button key="cancel" onClick={()=>props.onClose()}>
                Cancel
            </Button>
            <Button key="submit" type="primary" loading={updating} onClick={form.submit}>
                Save
            </Button>
        </div>}
    >
        <Form<PersonDto> form={form}
                         className={"compact"}
              colon={false}
              layout={"horizontal"}
              labelAlign={"right"}
              labelCol={{span: 6}}
              onFinish={saveObject}
              initialValues={{divisions:[{}]}}
              style={{paddingTop: 10}}>
            <Form.Item label="First Name"
                       name={'firstName'}
                       rules={[{ required: true, whitespace: true,  message: 'Required field' }]}>
                <Input/>
            </Form.Item>
            <Form.Item label="Middle Name(s)"
                       name={'middleName'}>
                <Input/>
            </Form.Item>
            <Form.Item label="Last Name"
                       name={'lastName'}
                       rules={[{ required: true, whitespace: true,  message: 'Required field' }]}
                       className={((form.getFieldValue('alternativeNames')?.length??0)>0)?"dashed-line":""}
            >
                <Input/>
            </Form.Item>
            <Form.List name={'alternativeNames'}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field, index) => (
                            <React.Fragment key={field.key}>
                                <Form.Item  label="First Name">
                                    <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                                        <Form.Item name={[field.name, 'firstName']}
                                                   rules={[{ required: true, whitespace: true,  message: 'Required field' }]}
                                                   style={{margin: 0, width: '100%'}}
                                                    >
                                            <Input/>
                                        </Form.Item>
                                        <Button icon={<DeleteOutlined />} onClick={()=>remove(index)} />
                                    </div>
                                </Form.Item>
                                <Form.Item label="Middle Name(s)"
                                           name={[field.name, 'middleName']}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item label="Last Name"
                                           name={[field.name, 'lastName']}
                                           rules={[{ required: true, whitespace: true,  message: 'Required field' }]}>
                                    <Input/>
                                </Form.Item>
                            </React.Fragment>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                style={{ width: '100%' }}
                                icon={<PlusOutlined />}
                                onClick={()=>add()}
                            >
                                Add alternative names
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
            <Form.Item label="Academic Rank"
                       name={'academicRank'}>
                <Select
                    options={Object.entries(Ranks).map(x=>({
                        value: x[0],
                        label: x[1]
                    }))}
                />
            </Form.Item>
            <Form.Item label="Rank Awarded On">
                <div style={{display: "flex", gap: 6}}>
                    <Form.Item name={['academicRankYear']} validateTrigger={'onBlur'}
                               rules={[{validator: (_, v) => checkYear(v)}]} style={{margin: 0, width: 100}}>
                        <Input placeholder={"Year"}/>
                    </Form.Item>
                    <Form.Item name={['academicRankMon']}>
                        <Select<number> defaultValue={null} options={months} style={{width: 70}}/>
                    </Form.Item>
                    <Form.Item name={['academicRankDay']}
                               validateTrigger={'onBlur'}
                               dependencies={[['academicRankMon']]}
                               rules={[
                                   ({getFieldValue}) => ({
                                       validator: (_, v) => {
                                           return checkDay(v, getFieldValue(['academicRankMon']))
                                       }
                                   })
                               ]}>
                        <Input placeholder={"Day"} style={{width: 55}}/>
                    </Form.Item>
                </div>
            </Form.Item>
            {rolesProvider.isAdmin() && <Form.Item label="Catalyst Person"
                                                   name={'harvardCatalystPersonId'}>
                <Input />
            </Form.Item>}
            <div style={{width: "100%", borderBottom: "dashed 1px #ddd", marginBottom: 10}}/>
            <Form.List name={'divisions'}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field, index) => (
                            <React.Fragment key={field.key}>
                                <Form.Item label="Division">
                                    <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                                        <Form.Item name={[field.name, 'divisionId']}
                                                   rules={[{ required: true, message: 'Required field' }]}
                                                   style={{margin: 0, width: '100%'}}>
                                            <Select<number>
                                                filterOption={true}
                                                options={(props.divisions??[]).map(x=>({label: x.title, value: x.id}))}
                                            />
                                        </Form.Item>
                                        {fields.length>1 && <Button icon={<DeleteOutlined />} onClick={()=>remove(index)} /> }
                                    </div>
                                </Form.Item>
                                <Form.Item label="Years">
                                    <div style={{display: "flex", gap: 6}} className={"years"}>
                                        <Form.Item name={[field.name,'startYear']} validateTrigger={'onBlur'} rules={[{validator: (_, v)=>checkYear(v)}]} style={{margin: 0, width: 100}}>
                                            <Input placeholder={"Start"} />
                                        </Form.Item>
                                        <Form.Item name={[field.name,'startMon']}>
                                            <Select<number> defaultValue={null} options={months} style={{width: 70}}/>
                                        </Form.Item>
                                        <Form.Item name={[field.name,'startDay']}
                                                   validateTrigger={'onBlur'}
                                                   dependencies={[['divisions', field.name,'startMon']]}
                                                   rules={[
                                                       ({getFieldValue})=>({
                                                           validator: (_, v)=>{
                                                               return checkDay(v, getFieldValue(['divisions', field.name,'startMon']))
                                                           }
                                                       })
                                                   ]}>
                                            <Input placeholder={"Day"} style={{width: 35}} />
                                        </Form.Item>
                                        <div style={{lineHeight: 2}}>&ndash;</div>
                                        <Form.Item  name={[field.name,'endYear']} validateTrigger={'onBlur'} rules={[{validator: (_, v)=>checkYear(v)}]} style={{margin: 0, width: 100}}>
                                            <Input placeholder={"End"} />
                                        </Form.Item>
                                        <Form.Item  name={[field.name,'endMon']} >
                                            <Select<number> defaultValue={null} options={months} style={{width: 70}}/>
                                        </Form.Item>
                                        <Form.Item  name={[field.name,'endDay']}
                                                    validateTrigger={'onBlur'}
                                                    dependencies={[['divisions', field.name,'endMon']]}
                                                    rules={[
                                                        ({getFieldValue})=>({
                                                            validator: (_, v)=>{
                                                                return checkDay(v, getFieldValue(['divisions', field.name,'endMon']))
                                                            }
                                                        })
                                                    ]}>
                                            <Input placeholder={"Day"} style={{width: 35}}  />
                                        </Form.Item>
                                    </div>
                                </Form.Item>
                            </React.Fragment>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                style={{ width: '100%' }}
                                icon={<PlusOutlined />}
                            >
                                Add more divisions
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
            {rolesProvider.isAdmin() && <Form.Item name={'wrongAffiliations'} label={'Ignore Affiliations'}>
                <TextArea rows={4} />
            </Form.Item>}
        </Form>
    </Modal>
}