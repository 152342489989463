import {Navigation} from "./Navigation";
import {Button, Input, Table} from "antd";
import {DoubleRightOutlined, FilterOutlined} from '@ant-design/icons';
import Column from "antd/es/table/Column";
import {useContext, useEffect, useState} from "react";
import {useApi} from "./ApiProvider"
import {useNavigate} from "react-router-dom";
import {useRolesProvider} from "../utils/keycloakUtils";
import {InstitutionDto, InstitutionStatsDto} from "../generated/api";
import {SettingsHolderContext} from "./SettingsHolder";
import './Institutions.scss'
import {OrgSettingsModal} from "./OrgSettingsModal";

export function Institutions() {
    const [datasource, setDatasource] = useState<InstitutionStatsDto[]>();
    const [filter, setFilter] = useState("");
    const [institutionModal,setInstitutionModal]=useState<{open: boolean, inst?: string}>({open: false});
    const settingsHolder = useContext(SettingsHolderContext)

    const api = useApi()

    const rolesProvider = useRolesProvider()

    const navigate = useNavigate();

    function refreshData() {
        api.listInstitutions().then(response => setDatasource(response.data))
    }

    useEffect(() => {
        refreshData()
    }, [])

    function sortAndFilter(datasource: InstitutionStatsDto[], filter: string) {
        const filterLowercase = filter.toLowerCase();
        let ret = datasource.filter(x => x.name.toLowerCase().includes(filterLowercase) || x.inst.toLowerCase().includes(filterLowercase))
        ret.sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'base'}))
        return ret
    }

    return <Navigation breadcrumbs={[{title: 'Organizations'}]} rawBreadcrumbs={true}>
        <OrgSettingsModal open={institutionModal.open}
                          inst={institutionModal.inst}
                           onClose={(added, deleted)=>{
                               setInstitutionModal({open: false})
                               if (added || deleted) {
                                   refreshData()
                               }
                           }} />
        <div className={"page-with-sub-header"}>
            <div className={"sub-header"}>
                <Input value={filter} onChange={e => setFilter(e.target.value)} size="large"
                       placeholder="filter by name" prefix={<FilterOutlined/>} style={{width: 200}}/>
                {
                    rolesProvider.isOrgAdmin() &&
                    <Button type={'primary'} size={"large"} onClick={() => setInstitutionModal({open: true})}>
                        Add new organization
                    </Button>
                }
                {rolesProvider.isAdmin() && <Button size={"large"} onClick={() => api.hcImport()}>HC import</Button>}
            </div>
            <div className={"page-content"}>
                {datasource !== undefined &&
                    <Table pagination={false} scroll={{y: '100%'}} dataSource={sortAndFilter(datasource, filter)}
                           rowKey={'inst'}>
                        <Column<InstitutionStatsDto> width={50} title={''} render={row=>{
                            return row.inst===settingsHolder?.inst?<DoubleRightOutlined />:
                                <Button className={"org-btn"} onClick={()=>{
                                    settingsHolder?.setInst(row.inst)
                                    navigate("/")
                                }}><DoubleRightOutlined /></Button>
                        }}/>
                        {rolesProvider.isOrgAdmin() && <Column<InstitutionDto> width={"10%"} title={'Tag'} dataIndex={'inst'}/>}
                        <Column<InstitutionStatsDto> title={'Name'} dataIndex={'name'}/>
                        <Column<InstitutionStatsDto> title={'Divisions'} width={100} dataIndex={'divisions'}/>
                        <Column<InstitutionStatsDto> title={'People'} width={100} dataIndex={'people'}/>
                        <Column<InstitutionStatsDto> title={'Publications'} width={100} dataIndex={'publications'}/>
                        <Column<InstitutionStatsDto> title={''} width={300}  render={row=><div style={{display:"flex", gap:10}}>
                            {row.inst!==settingsHolder?.inst && <Button type={"default"} onClick={() => {
                                settingsHolder?.setInst(row.inst)
                                navigate("/")
                            }}>Switch to</Button>}
                            {rolesProvider.isOrgAdmin() &&<>
                                <Button onClick={()=>{
                                    setInstitutionModal({open: true, inst: row.inst})
                                }}>Settings</Button>
                            </>}
                        </div>} />
                    </Table>}
            </div>
        </div>
    </Navigation>;
}

