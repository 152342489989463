// noinspection PointlessArithmeticExpressionJS

const palette = ["#F44336", "#673AB7", "#03A9F4", "#4CAF50", "#FF5722", "#607D8B", "#E91E63", "#3F51B5", "#00BCD4", "#8BC34A", "#FFC107", "#795548", "#9C27B0", "#2196F3", "#009688", "#CDDC39", "#FF9800", "#9E9E9E", "#EF9A9A", "#B39DDB", "#81D4FA", "#A5D6A7", "#FFF59D", "#FFAB91", "#B0BEC5", "#F48FB1", "#9FA8DA", "#80DEEA", "#C5E1A5", "#FFE082", "#BCAAA4", "#CE93D8", "#90CAF9", "#80CBC4", "#E6EE9C", "#FFCC80", "#B71C1C", "#311B92", "#01579B", "#1B5E20", "#F57F17", "#BF360C", "#263238", "#880E4F", "#1A237E", "#006064", "#33691E", "#FF6F00", "#3E2723", "#4A148C", "#0D47A1", "#004D40", "#827717", "#E65100", "#212121"]
const paletteConv = palette.map(x=>convertColor(x))

function deltaE(rgbA: number[], rgbB: number[]) {
    let labA = rgb2lab(rgbA);
    let labB = rgb2lab(rgbB);
    let deltaL = labA[0] - labB[0];
    let deltaA = labA[1] - labB[1];
    let deltaB = labA[2] - labB[2];
    let c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2]);
    let c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2]);
    let deltaC = c1 - c2;
    let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
    deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
    let sc = 1.0 + 0.045 * c1;
    let sh = 1.0 + 0.015 * c1;
    let deltaLKlsl = deltaL / (1.0);
    let deltaCkcsc = deltaC / (sc);
    let deltaHkhsh = deltaH / (sh);
    let i = deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh;
    return i < 0 ? 0 : Math.sqrt(i);
}

function rgb2lab(rgb: number[]){
    let r = rgb[0] / 255, g = rgb[1] / 255, b = rgb[2] / 255, x, y, z;
    r = (r > 0.04045) ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
    g = (g > 0.04045) ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
    b = (b > 0.04045) ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;
    x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
    y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.00000;
    z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;
    x = (x > 0.008856) ? Math.pow(x, 1/3) : (7.787 * x) + 16/116;
    y = (y > 0.008856) ? Math.pow(y, 1/3) : (7.787 * y) + 16/116;
    z = (z > 0.008856) ? Math.pow(z, 1/3) : (7.787 * z) + 16/116;
    return [(116 * y) - 16, 500 * (x - y), 200 * (y - z)]
}

function convertColor(hex: string): number[]{
    if (hex.startsWith("#")) hex = hex.substring(1)
    let chunks: number[] = [];
    if ( hex.length === 3){
        const tmp = hex.split("");
        for (let i = 0; i < 3; i++) {
            chunks.push(parseInt(tmp[i] + "" + tmp[i], 16));
        }
    }else if (hex.length === 6){
        const tmp = hex.match(/.{2}/g);
        if (tmp) {
            for (let i = 0; i < 3; i++) {
                chunks.push(parseInt(tmp[i], 16));
            }
        }
    }else {
        throw new Error("'"+hex+"' is not a valid hex format");
    }
    return chunks;
}

function conv(x: number) {
    return x.toString(16).padStart(2, "0")
}

export function augmentColors(colors: (string | undefined)[]): string[] {
    let notFilledIndexes = []
    let already: number[][] = []
    for (let i=0; i<colors.length; i++) {
        const cc = colors[i]
        if (cc) {
            already[i]=convertColor(cc)
        } else {
            notFilledIndexes.push(i)
        }
    }
    notFilledIndexes.forEach(notFilledIndex=>{
        let maxDistColor: number[]|undefined = undefined;
        let maxDistValue = 0;
        paletteConv.forEach(paletteConvColor=>{
            let minDistValue=1000
            already.filter(x=>!!x).forEach(alreadyColor=>{
                let currentDist = deltaE(alreadyColor, paletteConvColor)
                if (currentDist < minDistValue) {
                    minDistValue = currentDist;
                }
            })
            if (minDistValue > maxDistValue) {
                maxDistValue = minDistValue
                maxDistColor = paletteConvColor
            }
        })
        if (maxDistColor) already[notFilledIndex]=maxDistColor;
    })
    return already.map(x=>"#"+conv(x[0])+conv(x[1])+conv(x[2]));
}

export function getStandardColor(idx: number) {
    return palette[idx % palette.length]
}