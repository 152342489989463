import {useRolesProvider} from "../utils/keycloakUtils";
import {useApi} from "./ApiProvider"
import {PublicationDto, PublicationListItemDto} from "../generated/api";
import {Button, Popconfirm, Select, Typography} from "antd";
import {DeleteOutlined, FormOutlined, WarningOutlined} from "@ant-design/icons";
import {checkPosition, getSortFunc} from "./FilterAndSort";
import {formatPubJournal, formatPubSuffix} from "./PeoplePublications";
import React, {useState} from "react";

interface PublicationsListProps {
    personId: number
    data: PublicationDto[]
    startYear?: number
    endYear?: number
    position: string
    setRemovedCount?: (handler: (prevState: number | undefined) => number| undefined) => void
    setData?: (handler: (prevState: PublicationDto[]| undefined) => PublicationDto[]| undefined) => void
    sortPublications: string
    showAffiliations?: boolean
}

function safeGetAffiliation(pub: PublicationDto) {
    const aff = pub.authorsList[pub.position??0]?.affiliations
    if (!aff?.length || aff[0]==='') {
        return pub.authorsList[0]?.affiliations ?? ['']
    } else {
        return aff;
    }
}

function checkAffiliation(selectedAffiliation: string | undefined, pub: PublicationDto) {
    if (selectedAffiliation===undefined) return true;
    if (pub.position===undefined) return false;
    return safeGetAffiliation(pub).includes(selectedAffiliation)
}

interface AffiliationListProps {
    setSelectedAffiliation: (value: string | undefined) => void
    selectedAffiliation?: string
    publications: PublicationDto[]
}

function AffiliationList(props: AffiliationListProps) {
    const affiliationList = Object.entries(props.publications.reduce((acc: {[key: string]: number}, currentValue)=>{
        if (currentValue.position!==undefined) {
            const affiliationList = safeGetAffiliation(currentValue)
            if (affiliationList) {
                affiliationList.forEach(x => {
                    acc[x] = (acc[x] ?? 0) + 1
                })
            }
        }
        return acc
    }, {})).map(x=>({aff: x[0], count: x[1]})).sort((a,b)=>b.count-a.count)

    return <Select style={{width: "100%"}} options={[{
        value: "all",
        label: "Show all"
    }, ...affiliationList.map(x=>({
            value: x.aff,
            label: <div style={{display: "flex", gap: 8}}>
                <div>({x.count})</div>
                <div style={{overflow: "hidden", textOverflow: "ellipsis"}}
                     title={x.aff}>{x.aff?x.aff:"<No affiliation>"}</div>
            </div>
        }))]} onSelect={x=>props.setSelectedAffiliation(x==="all"?undefined:x)}
    />
}

interface ListDataProps extends PublicationsListProps {
    listData?: PublicationDto[]
    selectedAffiliation?: string
}

function ListData(props: ListDataProps) {
    const api = useApi()
    const rolesProvider = useRolesProvider();

    function addRemoved(article: PublicationListItemDto) {
        api.removeEntries(props.personId, [article.pmid]).then(() => {
            props.setRemovedCount?.(old => (old ?? 0) + 1)
            props.setData?.(oldState => oldState?.filter(x => x.pmid !== article.pmid))
        })
    }

    function removeManual(pub: PublicationDto) {
        api.removeFromManual(props.personId, [pub.pmid]).then(() => {
            props.setData?.(oldState => oldState?.filter(x => x.pmid !== pub.pmid))
        })
    }

    function getScore(item: PublicationListItemDto) {
        if (rolesProvider.isAdmin()) {
            if (item.score) {
                return <div style={{marginRight: 5}} title={item.scoreDebug}
                            className={item.maybe ? "maybe-tag" : "score-tag"}>{item.score.toFixed(2)}</div>
            }
        }
    }

    function controlButtons(pub: PublicationDto) {
        return <div style={{display: "inline-block", whiteSpace: "nowrap"}}>
            {rolesProvider.isAdmin() && pub.publicationStatus !== -1 && <Button icon={<DeleteOutlined/>}
                                                                                onClick={() => {
                                                                                    addRemoved(pub)
                                                                                }}
                                                                                danger={true}
                                                                                type={"text"}
                                                                                size={"small"}
                                                                                style={{marginLeft: 5}}
            />}
            {rolesProvider.isManager() && !rolesProvider.isAdmin() && pub.publicationStatus !== -1 && <Popconfirm
                title={"Report incorrect listing"}
                description={"Mark this publication as incorrectly listed, it will be removed from the list."}
                onConfirm={() => addRemoved(pub)}
            >
                <Button icon={<WarningOutlined/>}
                        className={'report-incorrect'}
                        danger={true}
                        title={"Report incorrect listing"}
                        type={"text"}
                        size={"small"}
                        style={{marginLeft: 5}}
                /></Popconfirm>}
            {rolesProvider.isManager() && pub.publicationStatus === -1 && <>
                <Typography.Text type={"warning"} title={"Manually added"}><FormOutlined/></Typography.Text>
                <Popconfirm
                    title={"Remove manually added"}
                    description={"Please confirm removing this manually added publications."}
                    onConfirm={() => removeManual(pub)}
                >
                    <Button icon={<DeleteOutlined/>}
                            danger={true}
                            title={"Remove manually added"}
                            type={"text"}
                            size={"small"}
                            style={{marginLeft: 5}}
                    /></Popconfirm>
            </>}
        </div>
    }

    return <ol style={{paddingLeft: 29}}>
        {(props.listData??[]).filter(x => ((!props.startYear || x.year === props.startYear || (props.endYear && x.year>=props.startYear && x.year<=props.endYear))
            && checkPosition(props.position, x.position, x.authors)
            && checkAffiliation(props.selectedAffiliation, x))).sort(getSortFunc(props.sortPublications)).map(x =>
            <li key={x.pmid} style={{paddingLeft: 6, paddingBottom: 6}} className={'publication-item'}>
                <div>{getScore(x)}{x.title} {formatPubSuffix(x)} {controlButtons(x)}</div>
                <div style={{color: '#aaa'}}>{x.authorsList.map((z, i) => {
                    return <React.Fragment key={z.lastName + z.initials}>
                        {i > 0 && <>, </>}
                        <span title={z.affiliations?.join("\n\n")}>
                                            {i === x.position &&
                                                <span
                                                    className={"highlight-author"}>{z.lastName + " " + z.initials}</span>}
                            {i !== x.position && z.lastName + " " + z.initials}
                                        </span>
                    </React.Fragment>
                })}

                </div>
                {formatPubJournal(x)}
            </li>)}
    </ol>
}

export function PublicationsList(props: PublicationsListProps) {
    const rolesProvider = useRolesProvider();
    const api = useApi()
    const [loadingExcluded, setLoadingExcluded] = useState(false);
    const [excluded, setExcluded] = useState<PublicationDto[]>()

    const [selectedAffiliation, setSelectedAffiliation] = useState<string>()

    return <>
        {rolesProvider.isAdmin() && props.showAffiliations && <AffiliationList
            publications={props.data.filter(x => ((!props.startYear || x.year === props.startYear) && checkPosition(props.position, x.position, x.authors)))}
            selectedAffiliation={selectedAffiliation}
            setSelectedAffiliation={setSelectedAffiliation}
        />}
        <ListData listData={props.data} selectedAffiliation={selectedAffiliation} {...props}/>
        {rolesProvider.isAdmin() &&
            <div>
                <Button onClick={()=>{
                    setLoadingExcluded(true)
                    api.listExcluded(props.personId).then(response=>{
                        setExcluded(response.data)
                        setLoadingExcluded(false)
                    })
                }} loading={loadingExcluded}>Show Excluded</Button>
                <ListData listData={excluded} selectedAffiliation={selectedAffiliation} {...props}/>
            </div>
        }
    </>
}