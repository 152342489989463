import {useForm} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {useApi} from "./ApiProvider";
import {SettingsHolderContext} from "./SettingsHolder";
import {Button, Checkbox, Form, Input, InputNumber, Modal, ModalProps, Popconfirm, Tabs} from "antd";
import TextArea from "antd/es/input/TextArea";
import {SettingsIntDto} from "../generated/api";

interface SettingsDtoFixed extends Omit<SettingsIntDto, 'orgNames' | 'keywords' | 'exactOrgNames' | 'exactOrgNamesIgnore'> {
    inst: string;
    orgNames?: string;
    exactOrgNames?: string;
    exactOrgNamesIgnore?: string;
    keywords: string;
}

function LogoInput(props: {value?: string, onChange?: (val: string)=>void}) {
    return <>
        {props.value &&  <img alt='logo' style={{maxWidth:300, maxHeight:100}} src={props.value} />}
        {!props.value && <>No logo</>}
        <div style={{display: "flex", gap: 10}}>
            <Input type={'file'} onChange={e=>{
                if (e.target.files) {
                    const reader = new FileReader();
                    reader.onloadend = function (e) {
                        if (e.target?.result && typeof(e.target.result)==='string') {
                            const name = e.target.result
                            props.onChange?.(name)
                        }
                    };
                    reader.readAsDataURL(e.target.files[0]);
                }
            }} style={{width: 400}}/>
            {props.value && <Button type='default' danger={true} onClick={()=>props.onChange?.('')}>Delete</Button>}
        </div>
    </>
}

interface OrgSettingsModalProps extends ModalProps {
    inst?: string,
    onClose: (added?: boolean, deleted?: boolean)=>void
}

export function OrgSettingsModal(props: OrgSettingsModalProps) {
    const [form] = useForm()
    const [updating, setUpdating] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const api = useApi()
    const settingsHolder = useContext(SettingsHolderContext)

    function saveObject(values: SettingsDtoFixed) {
        setUpdating(true);

        if (props.inst) {
            const inst = props.inst
            api.updateSettings(
                inst, {
                ...values,
                    orgNames: (values.orgNames ?? "").split('\n').map(x => x.trim()).filter(x => x),
                    exactOrgNames: (values.exactOrgNames ?? "").split('\n').map(x => x.trim()).filter(x => x),
                    exactOrgNamesIgnore: (values.exactOrgNamesIgnore ?? "").split('\n').map(x => x.trim()).filter(x => x),
                keywords: (values.keywords ?? "").split('\n').map(x => x.trim()).filter(x => x)
            }).then(response => {
                form.setFieldsValue(
                    {
                        ...response.data,
                        orgNames: response.data.orgNames?.join('\n'),
                        exactOrgNames: response.data.exactOrgNames?.join('\n'),
                        exactOrgNamesIgnore: response.data.exactOrgNamesIgnore?.join('\n'),
                        keywords: response.data.keywords?.join('\n'),
                    })
                setUpdating(false);
                settingsHolder?.setSettings(response.data)
                props.onClose(true)
            }).catch(()=>{
                setUpdating(false);
            })
        } else {
            api.addInstitution(values.inst, {
                ...values,
                orgNames: (values.orgNames ?? "").split('\n').map(x => x.trim()).filter(x => x),
                keywords: (values.keywords ?? "").split('\n').map(x => x.trim()).filter(x => x),
                exactOrgNames: (values.exactOrgNames ?? "").split('\n').map(x => x.trim()).filter(x => x),
                exactOrgNamesIgnore: (values.exactOrgNamesIgnore ?? "").split('\n').map(x => x.trim()).filter(x => x)
            }).then(()=>{
                setUpdating(false);
                props.onClose(true)
            }).catch(()=>{
                setUpdating(false);
            })
        }
    }

    function deleteObject() {
        if (props.inst) {
            setDeleting(true)
            api.delInstitution(props.inst).then(() => {
                setDeleting(false)
                props.onClose(undefined, true)
            })
        }
    }

    useEffect(() => {
        if (props.inst) {
            api.getSettingsInt(props.inst).then(response => form.setFieldsValue(
                {
                    ...response.data,
                    orgNames: response.data.orgNames?.join('\n'),
                    keywords: response.data.keywords?.join('\n'),
                    exactOrgNames: response.data.exactOrgNames?.join('\n'),
                    exactOrgNamesIgnore: response.data.exactOrgNamesIgnore?.join('\n'),
                    inst: props.inst
                }));
        }
    }, [form, api, props.inst])


    return <Modal
        {...props}
        width={640}
        destroyOnClose={true}
        title={props.inst?"Update organization":"New organization"}
        onCancel={()=>props.onClose()}
        afterClose={()=>{
            form.resetFields()
            setUpdating(false)
            setDeleting(false)
        }}
        footer={<div style={{display: 'flex'}}>
            {!!props.inst &&
                <Popconfirm
                    title="Delete the organization"
                    description="Are you sure to delete this organization?"
                    onConfirm={deleteObject}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button key="delete" danger={true} disabled={updating || deleting} loading={deleting}>
                        Delete
                    </Button>
                </Popconfirm>}
            <div className={"spacer"} />
            <Button key="cancel" onClick={()=>props.onClose()}>
                Cancel
            </Button>
            <Button key="submit" type="primary" loading={updating} onClick={form.submit} disabled={updating || deleting}>
                Save
            </Button>
        </div>}
    >
        <Form<SettingsDtoFixed> form={form} onFinish={saveObject} layout={"vertical"} initialValues={{startYear: 2000, citationsFeature: true}} disabled={updating || deleting}>
            <Tabs defaultActiveKey="settings" items={[
                {
                    key: 'general',
                    label: 'General',
                    forceRender: true,
                    style: {minHeight: 500},
                    children: <>
                        <Form.Item label={"Tag"} name={'inst'}
                                   rules={[{ required: true, whitespace: true,  message: 'Required field' }]}>
                            <Input readOnly={!!props.inst} disabled={!!props.inst}/>
                        </Form.Item>
                        <Form.Item label="Organization Name"
                                   name={'name'}
                                   rules={[{ required: true, whitespace: true,  message: 'Required field' }]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="Logo" name={'logo'} className={"line-aligned-form-item"}>
                            <LogoInput/>
                        </Form.Item>
                        <Form.Item label="Start Year"
                                   name={'startYear'}>
                            <InputNumber min={1980} max={2020}/>
                        </Form.Item>
                        <Form.Item label="Citation Feature"
                                   name={'citationsFeature'}
                                   valuePropName="checked">
                            <Checkbox/>
                        </Form.Item>
                        <Form.Item label="Projects Feature"
                                   name={'projectsFeature'}
                                   valuePropName="checked">
                            <Checkbox/>
                        </Form.Item>
                    </>
                }, {
                    key: 'affiliations',
                    label: 'Affiliations',
                    style: {minHeight: 500},
                    forceRender: true,
                    children: <>
                        <Form.Item label="Affiliations"
                                   name={'orgNames'}>
                            <TextArea rows={20}/>
                        </Form.Item>
                    </>
                }, {
                    key: 'foundPersons',
                    label: 'Auto Search',
                    style: {minHeight: 500},
                    forceRender: true,
                    children: <>
                        <Form.Item label="Affiliations (exact match)"
                                   name={'exactOrgNames'}>
                            <TextArea rows={5}/>
                        </Form.Item>
                        <Form.Item label="Ignore when having words"
                                   name={'exactOrgNamesIgnore'}>
                            <TextArea rows={5}/>
                        </Form.Item>
                        <div style={{marginBottom: 15}}>Found Last Refresh: {form.getFieldValue('foundLastRefresh')??"never"}</div>
                        <Form.Item label="Force Refresh"
                                   name={'forceRefreshFound'}
                                   valuePropName="checked">
                            <Checkbox/>
                        </Form.Item>
                    </>
                }, {
                    key: 'auth',
                    label: 'Auth',
                    forceRender: true,
                    style: {minHeight: 500},
                    children: <>
                        <Form.Item label="Auth Suffix"
                                   name={'authSuffix'}>
                            <Input/>
                        </Form.Item>
                    </>
                }
            ]} ></Tabs>
        </Form>
    </Modal>
}